import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Scope } from '@unform/core';
import { useLocation } from 'react-router-dom';
import {
  MdImage,
  MdDelete,
  MdOutlineAddPhotoAlternate,
  MdTableRows,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Checkbox from '@mui/material/Checkbox';
import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import { getNivel, setNivel } from '../../../lib/asyncUtils';
import { opPaginaTipo, opPaginaSituacao } from '../../../lib/inputOption';
import {
  opCatalogoTipoConversao,
  opPaginaCatalogoTipo,
  opPaginaCatalogoSituacao,
} from '../../../lib/const';
import { CSV, opExport, opImport } from '../../../lib/csv-core';

import TitleBar from '../../../components/TitleBar';
import { Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import InputFile from '../../../components/Form/Input/File';
import InputViewer from '../../../components/Form/Input/Viewer';
import AsyncCreatableSelectInput from '../../../components/Form/Input/AsyncCreatableSelect';
import TableHeader from '../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../components/Table';
import Pagination from '../../../components/EditorAI/Pagination';

import { Container, Wrapper, ActionNav } from './styles';

const schema = Yup.object().shape({
  descricao: Yup.string().required('O campo "Decrição" deve ser preenchido'),
  ordena: Yup.number()
    .typeError('O campo "Ordena" deve ser preenchido')
    .required('O campo "Ordena" deve ser preenchido'),
});

function CatalogoPagina() {
  const location = useLocation();
  const { id, paginaInicial = 1 } = location.state;

  const formRef = useRef(null);

  const [catalogo, setCatalogo] = useState(null);
  const [paginaCatalogo, setPaginaCatalogo] = useState(null);
  const [itemPagina, setItemPagina] = useState([]);

  /** controle de páginas */
  const [currentPage, setCurrentPage] = useState(null);
  const [, setTotalPage] = useState(0);

  /** controle de páginas dos itens */
  const paginationRef = useRef();
  const [currentPageItem, setCurrentPageItem] = useState(1);
  const [totalPageItem, setTotalPageItem] = useState(0);

  const paginaTipo = useMemo(() => opPaginaTipo.map((tipo) => tipo.label), []);
  const paginaSituacao = useMemo(
    () => opPaginaSituacao.map((situacao) => situacao.label),
    []
  );

  const itemTotal = useMemo(() => {
    if (catalogo) {
      const { itemPaginaTotal = 0 } =
        catalogo.paginaCatalogo.find((c) => c.idPagina === currentPage) || {};

      return itemPaginaTotal;
    }

    return 0;
  }, [catalogo, currentPage]);

  const carregaPagina = useCallback(() => {
    if (currentPage) {
      setPaginaCatalogo(null);
      api.get(`catalogo/${id}/pagina/${currentPage}`).then((response) => {
        const { nivelClassificacao } = response.data;

        /** formata valor para classificação */
        const fmtNivelClassificacao = nivelClassificacao.map(
          (classificacao) => {
            const {
              idCatalogo,
              idNivelClassificacao,
              nivel,
              descricao,
            } = classificacao;

            const valor = nivel
              ? {
                  value: nivel.idNivel,
                  label: `${nivel.codigo} - ${nivel.descricao}`,
                }
              : null;

            return {
              idCatalogo,
              nivel: idNivelClassificacao,
              descricao,
              valor,
            };
          }
        );

        setPaginaCatalogo({
          ...response.data,
          nivelClassificacao: fmtNivelClassificacao,
        });
      });
    }
  }, [id, currentPage]);

  const carregaItem = useCallback(
    (nextItem = false) => {
      if (currentPage) {
        api
          .get(`catalogo/${id}/pagina/${currentPage}/item`, {
            params: { page: nextItem ? currentPageItem : 1 },
          })
          .then((response) => {
            setItemPagina(
              response.data.itemPagina.map(({ quantidade, ...item }) => ({
                ...item,
                quantidade: quantidade.toFixed(4),
                checked: false,
              }))
            );

            setTotalPageItem(response.data.total);
            if (
              (currentPageItem > response.data.total || !nextItem) &&
              paginationRef.current
            ) {
              paginationRef.current.setPage(1);
              setCurrentPageItem(1);
            }
          });
      }
    },
    [id, currentPage, currentPageItem]
  );

  useEffect(() => {
    api.get(`catalogo/${id}/analise`).then((response) => {
      const { analise } = response.data;
      const { analisePaginaCatalogo } = analise || {};

      const { total = 0 } = analisePaginaCatalogo || {};

      setCatalogo(response.data);
      setCurrentPage(paginaInicial);
      setTotalPage(total);
    });
  }, [id, paginaInicial]);

  useEffect(() => {
    /** carrega página */
    carregaPagina();
  }, [currentPage, carregaPagina]);

  useEffect(() => {
    /** carrega os itens */
    carregaItem(true);
  }, [location, currentPageItem, carregaItem]);

  const handleNavegar = useCallback(
    (navegar) => {
      const { paginaCatalogo: paginas } = catalogo;

      const { idPagina } =
        paginas.find((pagina) => pagina.idPagina === currentPage + navegar) ||
        {};

      history.replace(location.pathname, {
        ...location.state,
        paginaInicial: idPagina || currentPage,
      });

      setCurrentPage(idPagina || currentPage);
    },
    [catalogo, currentPage, location]
  );

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { descricao, nota, niveis, ordena } = data;

        const fmtNivel = Object.getOwnPropertyNames(niveis).reduce(
          (acumulado, atributo) => {
            if (atributo.includes('nivel_') && niveis[atributo] !== '') {
              acumulado.push({
                // nivel: Number(atributo.replace(/\D/g, '')),
                idNivel: niveis[atributo],
              });
            }

            return acumulado;
          },
          []
        );

        /** envia todas as informações para serem persistidas */
        await api.put(`catalogo/${id}/pagina/${currentPage}`, {
          descricao,
          nota,
          nivelPagina: fmtNivel,
          ordena,
        });

        /** mensagem de sucesso */
        toast.success('Página salva com sucesso!');
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id, currentPage]
  );

  const handleUploadSVG = useCallback(
    async (e) => {
      try {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        /** enviar o arquivo para o servidor e recebe o local em que ele foi armazenado */
        await api.post(
          `/catalogo/${id}/pagina/${currentPage}/upload`,
          formData
        );

        carregaPagina();

        /** mensagem de sucesso */
        toast.success('Arquivo carregado com sucesso!');
      } catch (err) {
        AppError(err);
      }
    },
    [id, currentPage, carregaPagina]
  );

  const handleItemCriar = useCallback(() => {
    history.push('/catalogo/itens/criar', {
      background: location,
      idCatalogo: id,
      idPagina: currentPage,
    });
  }, [location, id, currentPage]);

  const handleItemAlterar = useCallback(
    (e, idItem, idMaterial) => {
      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        history.push('/catalogo/itens/editar', {
          background: location,
          idCatalogo: id,
          idPagina: currentPage,
          idItem,
          idMaterial,
        });
      }
    },
    [location, id, currentPage]
  );

  const handleAlterarItemChecagem = useCallback(
    (e, idItem, idMaterial, checked) => {
      e.stopPropagation();

      const index = itemPagina.findIndex(
        (i) => i.idItem === idItem && i.idMaterial === idMaterial
      );
      if (index > -1) {
        itemPagina[index].checked = checked;
        setItemPagina([...itemPagina]);
      }
    },
    [itemPagina]
  );

  const handleDeletarItem = useCallback(async () => {
    const itensDeletar = itemPagina.filter((i) => i.checked);

    try {
      if (itensDeletar.length === 0)
        throw new Error('Nenhum item foi selecionado para exclusão');

      const deletar = itensDeletar.map((item) => ({
        idMaterial: item.idMaterial,
        idItem: item.idItem,
      }));

      if (
        window.confirm(
          `Deseja realmente excluir os itens da página?\nCatálogo: ${id}\nPágina: ${currentPage}\nItens: ${deletar
            .map((item) => item.idItem)
            .join(',')}`
        )
      ) {
        await api.patch(`catalogo/${id}/pagina/${currentPage}/item`, {
          itens: deletar,
        });

        /** mensagem de sucesso */
        toast.success('Itens excluídos com sucesso!');

        carregaItem();
      }
    } catch (err) {
      AppError(err);
    }
  }, [id, itemPagina, currentPage, carregaItem]);

  const handleRedirecionarNavegacao = useCallback(
    (editor, idPagina) => {
      const { tipoConversao, paginaCatalogo: paginas } = catalogo;

      let paginaEditor = [];
      switch (editor) {
        case 'extrair':
          /** pega todas a página tipo tabela e misto */
          paginaEditor = paginas.filter((pagina) =>
            [opPaginaCatalogoTipo.TABELA, opPaginaCatalogoTipo.MISTO].includes(
              pagina.tipo
            )
          );
          break;
        case 'editar':
          /** pega todas a página tipo desenho e misto */
          paginaEditor = paginas.filter((pagina) =>
            [opPaginaCatalogoTipo.DESENHO, opPaginaCatalogoTipo.MISTO].includes(
              pagina.tipo
            )
          );
          break;
        case 'associar':
          /** pega todas a página tipo desenho e misto */
          paginaEditor = paginas.filter(
            (pagina) =>
              [
                opPaginaCatalogoTipo.DESENHO,
                opPaginaCatalogoTipo.MISTO,
              ].includes(pagina.tipo) &&
              [
                opPaginaCatalogoSituacao.DESENHO_EDITADO,
                opPaginaCatalogoSituacao.CONCLUIDO,
              ].includes(pagina.situacao)
          );
          break;
        default:
          break;
      }

      const paginaIndex = paginaEditor.findIndex(
        (pagina) => pagina.idPagina === idPagina
      );

      switch (Number(tipoConversao)) {
        case opCatalogoTipoConversao.INTELIGENCIA_ARTIFICIAL:
          history.push(`/edicao/${editor}AI`, {
            id,
            paginaInicial: paginaIndex + 1,
          });
          break;
        case opCatalogoTipoConversao.MANUAL:
          history.push(`/edicao/${editor}`, {
            id,
            paginaInicial: paginaIndex + 1,
          });
          break;
        default:
          break;
      }
    },
    [id, catalogo]
  );

  const handleImportar = useCallback(() => {
    history.push(`/catalogo/item/importar`, {
      background: { ...location },
      parametros: {
        op: opImport.PAGINA_ITEMPAGINA,
        idCatalogo: id,
        idPagina: currentPage,
      },
    });
  }, [id, currentPage, location]);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.PAGINA_ITEMPAGINA, {
      idCatalogo: id,
      idPagina: currentPage,
    });
  }, [id, currentPage]);

  return (
    <Container>
      <TitleBar
        back
        title="Editar página"
        withNavigation
        onPrevNav={() => handleNavegar(-1)}
        onNextNav={() => handleNavegar(+1)}
      />

      {paginaCatalogo && (
        <Wrapper>
          <Form
            ref={formRef}
            initialData={paginaCatalogo}
            onSubmit={handleConfirmar}
            autoComplete="off"
          >
            <Form.Row>
              <Form.Column>
                <InputFile
                  id="upload"
                  name="upload"
                  label="Carrega novo arquivo"
                  accept="application/pdf"
                  height={300}
                  // canUpload={[1, 2, 3].includes(paginaCatalogo.tipo)}
                  canUpload={false}
                  backgroundURL={
                    paginaCatalogo.urlCard || paginaCatalogo.urlEdicao
                  }
                  handleChange={handleUploadSVG}
                />
              </Form.Column>
              <Form.Column>
                <Form.Row>
                  <InputViewer
                    id="idCatalogo"
                    name="catalogo.idCatalogo"
                    label="Catálogo"
                  />
                  <InputViewer id="idPagina" name="idPagina" label="Página" />
                  <Form.Row.PageType type={paginaCatalogo.tipo}>
                    {paginaTipo[paginaCatalogo.tipo]}
                  </Form.Row.PageType>
                  <Form.Row.PageStatus status={paginaCatalogo.situacao}>
                    {paginaSituacao[paginaCatalogo.situacao]}
                  </Form.Row.PageStatus>
                </Form.Row>

                {[
                  opPaginaCatalogoTipo.DESENHO,
                  opPaginaCatalogoTipo.MISTO,
                ].includes(paginaCatalogo.tipo) && (
                  <>
                    <Scope path="niveis">
                      {paginaCatalogo.nivelClassificacao.map((nivel) => (
                        <AsyncCreatableSelectInput
                          key={nivel.nivel}
                          id={nivel.nivel}
                          name={`nivel_${nivel.nivel}`}
                          label={nivel.descricao}
                          defaultOptions
                          loadOptions={(value, cb) =>
                            getNivel({ id, nivel: nivel.nivel, value }, cb)
                          }
                          onCreate={(e, value) => {
                            setNivel({
                              e,
                              id,
                              value,
                            });
                          }}
                          defaultValue={nivel.valor && nivel.valor}
                        />
                      ))}
                    </Scope>
                    <Input id="descricao" name="descricao" label="Descrição" />
                    <Form.Row>
                      <Input id="nota" name="nota" label="Nota" />
                      <Input
                        id="ordena"
                        name="ordena"
                        label="Ordem"
                        width={200}
                      />
                    </Form.Row>
                  </>
                )}
              </Form.Column>
            </Form.Row>

            <>
              <Form.Separate height={40} />
              <Form.Footer withDirectNav>
                <ActionNav>
                  {[
                    opPaginaCatalogoTipo.TABELA,
                    opPaginaCatalogoTipo.MISTO,
                  ].includes(paginaCatalogo.tipo) && (
                    <ActionNav.BtnSquare
                      id="btn-extrair"
                      className="btn-nav-ref"
                      title="Navegar para o processo de Extração"
                      type="button"
                      onClick={() =>
                        handleRedirecionarNavegacao('extrair', currentPage)
                      }
                    >
                      <MdTableRows size={30} />
                    </ActionNav.BtnSquare>
                  )}

                  {[
                    opPaginaCatalogoTipo.DESENHO,
                    opPaginaCatalogoTipo.MISTO,
                  ].includes(paginaCatalogo.tipo) && (
                    <ActionNav.BtnSquare
                      id="btn-editar"
                      className="btn-nav-ref"
                      title="Navegar para o processo de Edição"
                      type="button"
                      onClick={() =>
                        handleRedirecionarNavegacao('editar', currentPage)
                      }
                    >
                      <MdImage size={30} />
                    </ActionNav.BtnSquare>
                  )}

                  {[
                    opPaginaCatalogoTipo.DESENHO,
                    opPaginaCatalogoTipo.MISTO,
                  ].includes(paginaCatalogo.tipo) &&
                    [
                      opPaginaCatalogoSituacao.DESENHO_EDITADO,
                      opPaginaCatalogoSituacao.CONCLUIDO,
                    ].includes(paginaCatalogo.situacao) && (
                      <ActionNav.BtnSquare
                        id="btn-associar"
                        className="btn-nav-ref"
                        title="Navegar para o processo de Mapeamento"
                        type="button"
                        onClick={() =>
                          handleRedirecionarNavegacao('associar', currentPage)
                        }
                      >
                        <MdOutlineAddPhotoAlternate size={30} />
                      </ActionNav.BtnSquare>
                    )}
                </ActionNav>

                {[
                  opPaginaCatalogoTipo.DESENHO,
                  opPaginaCatalogoTipo.MISTO,
                ].includes(paginaCatalogo.tipo) && (
                  <button id="btn-submit" type="submit">
                    Salvar
                  </button>
                )}
              </Form.Footer>
            </>
          </Form>
        </Wrapper>
      )}

      {paginaCatalogo &&
        [opPaginaCatalogoTipo.DESENHO, opPaginaCatalogoTipo.MISTO].includes(
          paginaCatalogo.tipo
        ) && (
          <>
            <TitleBar
              title="Itens da página"
              subtitle={`Total de itens: ${itemTotal}`}
              width={2000}
              onFileUpload={handleImportar}
              onFileDownload={handleExportar}
            >
              <ActionNav.BtnSquare
                className="btn-delete"
                onClick={handleDeletarItem}
              >
                <MdDelete size={30} />
              </ActionNav.BtnSquare>
              <ActionNav.BtnDefault
                id="btn-new"
                type="button"
                onClick={handleItemCriar}
              >
                Novo
              </ActionNav.BtnDefault>
            </TitleBar>

            <>
              <Table>
                <TableHeader
                  withPadding
                  titles={[
                    { fieldname: 'idItem', title: 'Item' },
                    { fieldname: 'partnumber', title: 'Partnumber' },
                    { fieldname: 'descricao', title: 'Descrição' },
                    { fieldname: 'quantidade', title: 'Quantidade' },
                    { fieldname: 'ordena', title: 'Ordena' },
                    { fieldname: null, title: null },
                  ]}
                  cbSort={() => {}}
                />
                <tbody>
                  {itemPagina.map((item, index) => (
                    <React.Fragment key={index}>
                      <RowMaster
                        onClick={(e) =>
                          handleItemAlterar(e, item.idItem, item.idMaterial)
                        }
                      >
                        <td>{item.idItem}</td>
                        <td>{item.materialFabricante.partnumber}</td>
                        <td>{item.materialFabricante.descricao}</td>
                        <td>{item.quantidade}</td>
                        <td>{item.ordena}</td>
                        <td>
                          <Checkbox
                            id={`item-${index}`}
                            name={`item-${index}`}
                            checked={item.checked}
                            disableRipple
                            onChange={(e) => {
                              handleAlterarItemChecagem(
                                e,
                                item.idItem,
                                item.idMaterial,
                                e.target.checked
                              );
                            }}
                          />
                        </td>
                      </RowMaster>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
              <Pagination
                ref={paginationRef}
                pageCount={totalPageItem}
                initialData={1}
                onPageChange={(page) => {
                  setCurrentPageItem(page);
                }}
              />
            </>
          </>
        )}
    </Container>
  );
}

export default CatalogoPagina;
