import React, {
  useState,
  useMemo,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  MdMoreVert,
  MdDelete,
  MdEdit,
  MdVisibility,
  MdCloudDownload,
  MdLock,
  MdSettings,
  MdSettingsSuggest,
  MdGeneratingTokens,
  MdCopyAll,
  MdOutlineQrCode2,
} from 'react-icons/md';
import { FaEnvelope, FaFilePdf, FaFileCsv } from 'react-icons/fa';
import PropTypes from 'prop-types';

import {
  Container,
  Wrapper,
  NotificationWrapper,
  NotificationBadge,
} from './styles';

const ActionMenu = forwardRef(
  (
    {
      Icon,
      data,
      onPublish,
      onSendToApprove,

      onLineDownload,
      onLineEdit,
      onLineRemove,
      onLineUserPassword,
      onLineManager,
      onLineGenerateToken,
      onLineGenerateQRCode,
      onLineCopy,

      onItemKit,
      onRemove,
      onEdit,
      onView,
      onManager,
      onGenerateToken,
      onLineView,
      onUserProfile,
      onUserPassword,
      onUserPurchase,
      onUserEdit,
      onUserAtive,
      onUserInactive,

      onUserAccount,

      onExportCSV,
      onExportPDF,
      onMail,
      onCopy,

      onAppExit,

      useDownloadBadgeIndicator,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);

    const handleVisible = useCallback(() => {
      setVisible((state) => !state);
    }, []);

    useImperativeHandle(ref, () => ({
      handleVisible,
    }));

    const DownloadNotification = useMemo(
      () => (
        <NotificationWrapper>
          {useDownloadBadgeIndicator && <NotificationBadge />}
          <MdCloudDownload size={25} />
        </NotificationWrapper>
      ),
      [useDownloadBadgeIndicator]
    );

    return (
      <Container onBlur={() => setVisible(false)}>
        {onLineManager && (
          <button
            type="button"
            id="btn-line-manager"
            onMouseDown={() => onLineManager(data)}
          >
            <MdSettings size={25} />
          </button>
        )}

        {onLineGenerateToken && (
          <button
            type="button"
            id="btn-line-generate-token"
            onMouseDown={() => onLineGenerateToken(data)}
          >
            <MdGeneratingTokens size={25} />
          </button>
        )}

        {onLineGenerateQRCode && (
          <button
            type="button"
            id="btn-line-generate-qrcode"
            title="Gerar QR Code"
            onMouseDown={() => onLineGenerateQRCode(data)}
          >
            <MdOutlineQrCode2 size={25} />
          </button>
        )}

        {onLineCopy && (
          <button
            type="button"
            id="btn-line-copy"
            onMouseDown={() => onLineCopy(data)}
          >
            <MdCopyAll size={25} />
          </button>
        )}

        {onLineView && (
          <button
            type="button"
            id="btn-line-view"
            onMouseDown={() => onLineView(data)}
          >
            <MdVisibility size={25} />
          </button>
        )}

        {onLineDownload && (
          <button
            type="button"
            id="btn-line-download"
            onMouseDown={() => onLineDownload(data)}
          >
            {DownloadNotification}
          </button>
        )}

        {onLineUserPassword && (
          <button
            type="button"
            id="btn-line-user-password"
            onMouseDown={() => onLineUserPassword(data)}
          >
            <MdLock size={25} />
          </button>
        )}

        {onLineEdit && (
          <button
            type="button"
            id="btn-line-edit"
            onMouseDown={() => onLineEdit(data)}
          >
            <MdEdit size={25} />
          </button>
        )}

        {onItemKit && (
          <button
            type="button"
            id="btn-line-remove"
            onMouseDown={() => onItemKit()}
          >
            <MdSettingsSuggest size={25} />
          </button>
        )}

        {onLineRemove && (
          <button
            type="button"
            id="btn-line-remove"
            onMouseDown={() => onLineRemove(data)}
          >
            <MdDelete size={25} />
          </button>
        )}

        <button type="button" id="btn-action" onClick={handleVisible}>
          {typeof Icon === 'string' ? Icon : <Icon size={25} />}
        </button>

        <Wrapper visible={visible} onBlur={() => setVisible(false)}>
          <ul>
            {onView && (
              <li>
                <button
                  type="button"
                  id="act-view"
                  onMouseDown={() => onView(data)}
                >
                  Visualizar
                </button>
              </li>
            )}

            {onPublish && (
              <li>
                <button
                  type="button"
                  id="act-publish"
                  onMouseDown={() => onPublish(data)}
                >
                  {data && data.situacao === 2 ? 'Despublicar' : 'Publicar'}
                </button>
              </li>
            )}

            {onSendToApprove && (
              <li>
                <button
                  type="button"
                  id="act-send-appove"
                  onMouseDown={() => onSendToApprove(data)}
                >
                  Enviar para aprovação
                </button>
              </li>
            )}

            {onEdit && (
              <li>
                <button
                  type="button"
                  id="act-edit"
                  onMouseDown={() => onEdit(data)}
                >
                  Editar
                </button>
              </li>
            )}

            {onManager && (
              <li>
                <button
                  type="button"
                  id="act-manager"
                  onMouseDown={() => onManager(data)}
                >
                  Gerenciar
                </button>
              </li>
            )}

            {onGenerateToken && (
              <li>
                <button
                  type="button"
                  id="act-generate-token"
                  onMouseDown={() => onGenerateToken(data)}
                >
                  Gerar Token
                </button>
              </li>
            )}

            {onCopy && (
              <li>
                <button
                  type="button"
                  id="act-copy"
                  onMouseDown={() => onCopy(data)}
                >
                  Copiar
                </button>
              </li>
            )}

            {onRemove && (
              <li>
                <button
                  type="button"
                  id="act-remove"
                  onMouseDown={() => onRemove(data)}
                >
                  Remover
                </button>
              </li>
            )}

            {onUserEdit && (
              <li>
                <button
                  type="button"
                  id="act-user-edit"
                  onMouseDown={() => onUserEdit(data)}
                >
                  Editar dados
                </button>
              </li>
            )}

            {onUserProfile && (
              <li>
                <button
                  type="button"
                  id="act-user-profile"
                  onMouseDown={() => onUserProfile(data)}
                >
                  Meu Perfil
                </button>
              </li>
            )}

            {onUserAccount && (
              <li>
                <button
                  type="button"
                  id="act-user-account"
                  onMouseDown={() => onUserAccount(data)}
                >
                  Minha Conta
                </button>
              </li>
            )}

            {onUserPassword && (
              <li>
                <button
                  type="button"
                  id="act-user-password"
                  onMouseDown={() => onUserPassword(data)}
                >
                  Alterar Senha
                </button>
              </li>
            )}

            {onUserPurchase && (
              <li>
                <button
                  type="button"
                  id="act-user-purchase"
                  onMouseDown={() => onUserPurchase(data)}
                >
                  Minhas Solicitações
                </button>
              </li>
            )}

            {onUserAtive && (
              <li>
                <button
                  type="button"
                  id="act-user-active"
                  onMouseDown={() => onUserAtive(data)}
                >
                  Ativar usuário
                </button>
              </li>
            )}

            {onUserInactive && (
              <li>
                <button
                  type="button"
                  id="act-user-inactive"
                  onMouseDown={() => onUserInactive(data)}
                >
                  Inativar usuário
                </button>
              </li>
            )}

            {onExportCSV && (
              <li>
                <button
                  type="button"
                  id="act-export-csv"
                  onMouseDown={() => onExportCSV(data)}
                >
                  <FaFileCsv size={18} />
                  Exportar CSV
                </button>
              </li>
            )}

            {onExportPDF && (
              <li>
                <button
                  type="button"
                  id="act-export-pdf"
                  onMouseDown={() => onExportPDF(data)}
                >
                  <FaFilePdf size={18} />
                  Exportar PDF
                </button>
              </li>
            )}

            {onMail && (
              <li>
                <button
                  type="button"
                  id="act-mail"
                  onMouseDown={() => onMail(data)}
                >
                  <FaEnvelope size={18} />
                  Enviar E-mail
                </button>
              </li>
            )}

            {onAppExit && (
              <li>
                <button
                  type="button"
                  id="act-exit"
                  onMouseDown={() => onAppExit(data)}
                >
                  Sair
                </button>
              </li>
            )}
          </ul>
        </Wrapper>
      </Container>
    );
  }
);

ActionMenu.propTypes = {
  data: PropTypes.instanceOf(Object),
  Icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onPublish: PropTypes.func,
  onSendToApprove: PropTypes.func,
  onLineDownload: PropTypes.func,
  onRemove: PropTypes.func,
  onLineUserPassword: PropTypes.func,
  onLineEdit: PropTypes.func,
  onLineRemove: PropTypes.func,
  onLineCopy: PropTypes.func,
  // onLineEditor: PropTypes.func,
  onItemKit: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onManager: PropTypes.func,
  onGenerateToken: PropTypes.func,
  onUserPassword: PropTypes.func,
  onLineManager: PropTypes.func,
  onLineGenerateToken: PropTypes.func,
  onLineGenerateQRCode: PropTypes.func,
  onLineView: PropTypes.func,
  onUserProfile: PropTypes.func,
  onUserEdit: PropTypes.func,
  onUserAtive: PropTypes.func,
  onUserInactive: PropTypes.func,
  onUserPurchase: PropTypes.func,
  onUserAccount: PropTypes.func,

  onExportCSV: PropTypes.func,
  onExportPDF: PropTypes.func,
  onMail: PropTypes.func,
  onCopy: PropTypes.func,

  onAppExit: PropTypes.func,

  useDownloadBadgeIndicator: PropTypes.bool,
};

ActionMenu.defaultProps = {
  data: null,
  Icon: MdMoreVert,
  onPublish: null,
  onSendToApprove: null,
  onLineDownload: null,
  onRemove: null,
  onLineUserPassword: null,
  onLineEdit: null,
  onLineRemove: null,
  onLineCopy: null,
  // onLineEditor: null,
  onItemKit: null,
  onEdit: null,
  onView: null,
  onManager: null,
  onGenerateToken: null,
  onUserPassword: null,
  onLineManager: null,
  onLineGenerateToken: null,
  onLineGenerateQRCode: null,
  onLineView: null,
  onUserProfile: null,
  onUserEdit: null,
  onUserAtive: null,
  onUserInactive: null,
  onUserPurchase: null,
  onUserAccount: null,

  onExportCSV: null,
  onExportPDF: null,
  onMail: null,
  onCopy: null,

  onAppExit: null,

  useDownloadBadgeIndicator: false,
};

export default ActionMenu;
