import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';

import { CSV, opExport } from '../../../lib/csv-core';

import { opTipoAcesso } from '../../../lib/const';
import { opSituacao, opAdministrador } from '../../../lib/inputOption';

import ActionMenu from '../../../components/ActionMenu';
import TitleBar from '../../../components/TitleBar';
import TableHeader from '../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../components/Table';
import Pagination from '../../../components/EditorAI/Pagination';

function EmpresaUsuario() {
  const location = useLocation();
  const { idEmpresa } = location.state;

  const { adm: tipoAcesso } = useSelector((state) => state.user.profile);

  const [empresa, setEmpresa] = useState(null);
  const [usuarios, setUsuarios] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaUsuario = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page, ...flt } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
      ...flt,
    };

    api
      .get(`empresa/${idEmpresa}/usuario`, {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresa(response.data.empresa);
        setUsuarios(
          response.data.usuario.map(({ adm, dataDesa, ...usuario }) => ({
            ...usuario,
            dataDesa,
            adm: opAdministrador[adm].label,
            situacao: dataDesa ? opSituacao[0].label : opSituacao[1].label,
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa]);

  const handleCriar = useCallback(() => {
    history.push(`/empresa/usuario/criar`, { background: location, idEmpresa });
  }, [location, idEmpresa]);

  const handleEditar = useCallback(
    (idUsuario) => {
      history.push(`/empresa/usuario/editar`, {
        background: location,
        idEmpresa,
        idUsuario,
      });
    },
    [location, idEmpresa]
  );

  const handleTrocarSenha = useCallback(
    (idUsuario) => {
      history.push(`/empresa/usuario/trocar`, {
        background: location,
        id: idUsuario,
      });
    },
    [location]
  );

  const handleRemover = useCallback(
    async (idUsuario) => {
      if (window.confirm('Deseja realmente excluir o usuário?')) {
        try {
          await api.delete(`empresa/${idEmpresa}/usuario/${idUsuario}`);

          carregaUsuario();

          /** mensagem de sucesso */
          toast.success('Usuário excluído com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [idEmpresa, carregaUsuario]
  );

  const handleVisualizar = useCallback(
    (e, idUsuario) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idUsuario);
      }
    },
    [handleEditar]
  );

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.EMPRESA_USUARIO, { idEmpresa });
  }, [idEmpresa]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaUsuario();
  }, [location, currentPage, carregaUsuario]);

  return (
    <>
      <TitleBar
        back
        title={empresa ? `${empresa.descricao} / Usuários` : ''}
        isSearchable
        filterSearchType="EMPRESA_USUARIO"
        onNew={handleCriar}
        onFileDownload={handleExportar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'nome', title: 'Nome' },
            { fieldname: 'login', title: 'Login' },
            { fieldname: 'email', title: 'E-mail' },
            { fieldname: 'adm', title: 'Administrador' },
            { fieldname: 'situacao', title: 'Situação' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {usuarios.map((usuario) => (
            <RowMaster
              key={usuario.idUsuario}
              onClick={(e) => handleVisualizar(e, usuario.idUsuario)}
            >
              <td>{usuario.nome}</td>
              <td>{usuario.login}</td>
              <td>{usuario.email}</td>
              <td>{usuario.adm}</td>
              <td>{usuario.situacao}</td>
              <td>
                {tipoAcesso === opTipoAcesso.ADMIN_PLATAFORMA && (
                  <ActionMenu
                    onEdit={() => handleEditar(usuario.idUsuario)}
                    onRemove={() => handleRemover(usuario.idUsuario)}
                    onLineRemove={() => handleRemover(usuario.idUsuario)}
                    onLineUserPassword={() =>
                      handleTrocarSenha(usuario.idUsuario)
                    }
                  />
                )}

                {tipoAcesso === opTipoAcesso.ADMIN_EMPRESA && (
                  <ActionMenu
                    onEdit={() => handleEditar(usuario.idUsuario)}
                    onRemove={() => handleRemover(usuario.idUsuario)}
                    onLineRemove={() => handleRemover(usuario.idUsuario)}
                  />
                )}
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaUsuario };
