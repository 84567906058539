import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import { getTemplate } from '../../../lib/asyncUtils';
import {
  opIdioma,
  opTipoItemPagina,
  opTipoSequenciaPagina,
} from '../../../lib/inputOption';
import { opSimNao } from '../../../lib/const';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import CreatableSelect from '../../../components/Form/Input/CreatableSelect';
import Select from '../../../components/Form/Input/Select';
import AsyncSelect from '../../../components/Form/Input/AsyncSelect';
import ImgFile from '../../../components/Form/Input/ImgFile';

function CatalogoEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { id } = location.state;

  const [catalogo, setCatalogo] = useState();
  const [nivelClassificacao, setNivelClassificacao] = useState([]);

  const carregaCatalogo = useCallback(() => {
    api.get(`catalogo/${id}`).then((response) => {
      const {
        idioma,
        instAlt,
        instPubl,
        instDown,
        arquivo,
        series,
        iaTipoItemPagina,
        iaTipoSequenciaPagina,
        template,
      } = response.data;

      /** formata campo fabricante */
      const auxFabricante = arquivo.fabricante && arquivo.fabricante.descricao;
      /** formata campo modelo */
      const auxModelo = arquivo.modelos
        .map((modelo) => modelo.descricao)
        .join(', ');

      const fmtSerieCatalogo = series.map((seca) => ({
        label: seca.numeroSerie,
        value: seca.numeroSerie,
        isFixed: true,
      }));

      /** formata campo idioma */
      const fmtIdioma = opIdioma[idioma] ? opIdioma[idioma].label : '';

      /** formata campo IA tipo item página  */
      const fmtIATipoItemPagina = opTipoItemPagina[iaTipoItemPagina]
        ? opTipoItemPagina[iaTipoItemPagina].value
        : '';

      /** formata campo IA tipo página  */
      const fmtIATipoSequenciaPagina = opTipoSequenciaPagina[
        iaTipoSequenciaPagina
      ]
        ? opTipoSequenciaPagina[iaTipoSequenciaPagina].value
        : '';

      /** formata data */
      const fmtInstAlt = instAlt
        ? format(parseISO(instAlt), 'dd/MM/yyyy HH:mm:ss')
        : null;

      /** formata data */
      const fmtInstPubl = instPubl
        ? format(parseISO(instPubl), 'dd/MM/yyyy HH:mm:ss')
        : null;

      /** formata data */
      const fmtInstDown = instDown
        ? format(parseISO(instDown), 'dd/MM/yyyy HH:mm:ss')
        : null;

      setCatalogo({
        ...response.data,
        idioma: fmtIdioma,
        iaTipoItemPagina: fmtIATipoItemPagina,
        iaTipoSequenciaPagina: fmtIATipoSequenciaPagina,
        instAlt: fmtInstAlt,
        instPubl: fmtInstPubl,
        instDown: fmtInstDown,
        fabricante: auxFabricante,
        modelo: auxModelo,
      });

      setNivelClassificacao(
        response.data.nivelClassificacoes.map(({ nivel, descricao }) => ({
          nivel,
          id: `classificacao_${nivel}`,
          name: `classificacao_${nivel}`,
          label: `Rótulo Nível ${nivel}`,
          descricao,
        }))
      );

      formRef.current.setFieldValue('numeroSerie', fmtSerieCatalogo);

      if (template) {
        const { idTemplate, nome } = template;
        const fmtTemplate = { value: idTemplate, label: nome };
        formRef.current.setFieldValue('idTemplate', fmtTemplate);
      }
    });
  }, [id]);

  useEffect(() => {
    carregaCatalogo();
  }, [carregaCatalogo]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        const {
          nome,
          versao,
          numeroSerie,
          iaTipoItemPagina,
          iaTipoSequenciaPagina,
          idTemplate,
        } = data;

        const classificacao = [];
        Object.getOwnPropertyNames(data).forEach((attr) => {
          if (attr.includes('classificacao_')) {
            classificacao.push({
              nivel: Number(attr.replace(/\D/g, '')),
              descricao: data[attr],
            });
          }
        });

        /** submete os dados */
        await api.put(`catalogo/${id}`, {
          nome,
          versao,
          numeroSerie,
          iaTipoItemPagina,
          iaTipoSequenciaPagina,
          nivelClassificacao: classificacao,
          idTemplate: idTemplate ? Number(idTemplate) : null,
        });

        /** mensagem de sucesso */
        toast.success('Catálogo atualizado com sucesso!');

        /** volta para página anterior */
        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id]
  );

  const handleImagem = useCallback(
    async (data) => {
      try {
        if (data) {
          const { files = [] } = data.target || {};

          if (files.length > 0) {
            const formData = new FormData();
            formData.append('file', files[0]);

            await api.patch(`catalogo/${id}`, formData);

            /** recarrega catálogo */
            carregaCatalogo();

            /** mensagem de sucesso */
            toast.success('Catálogo atualizado com sucesso!');
          }
        }
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id, carregaCatalogo]
  );

  const handleRemoverImagem = useCallback(async () => {
    try {
      await api.patch(`catalogo/${id}`);

      /** recarrega catálogo */
      carregaCatalogo();

      /** mensagem de sucesso */
      toast.success('Catálogo atualizado com sucesso!');
    } catch (err) {
      AppError(err, formRef);
    }
  }, [id, carregaCatalogo]);

  return (
    <>
      <TitleBar back={!!location.background} title="Editar catalogo" />

      <FormWrapper>
        {catalogo && (
          <Form
            id="formCatalogo"
            ref={formRef}
            initialData={catalogo}
            onSubmit={handleConfirmar}
            autoComplete="off"
          >
            <Form.Column>
              <Form.Row>
                <Input
                  id="instAlt"
                  name="instAlt"
                  label="Data da última alteração"
                  type="text"
                  disabled
                />
                <Input
                  id="instPubl"
                  name="instPubl"
                  label="Data da publicação"
                  type="text"
                  disabled
                />
                <Input
                  id="instDown"
                  name="instDown"
                  label="Data da publicação (Mobile)"
                  type="text"
                  disabled
                />
              </Form.Row>
              <Form.Row>
                <Input
                  id="nome"
                  name="nome"
                  label="Classe Operacional"
                  type="text"
                />
                <Input
                  id="modelo"
                  name="modelo"
                  label="Modelo"
                  type="text"
                  disabled
                />
                <Input
                  id="fabricante"
                  name="fabricante"
                  label="Fabricante"
                  type="text"
                  disabled
                />
              </Form.Row>
              <Form.Row>
                <Input id="versao" name="versao" label="Versão" type="text" />
                <CreatableSelect
                  id="numeroSerie"
                  name="numeroSerie"
                  label="Número de Série"
                  placeholder="Insira o número de série e pressione enter..."
                  isMulti
                />
                {process.env.REACT_APP_EXPERIMENTAL_CATALOGO_TEMPLATE ===
                  opSimNao.SIM && (
                  <AsyncSelect
                    id="idTemplate"
                    name="idTemplate"
                    label="Template"
                    cacheOptions
                    defaultOptions
                    isClearable
                    loadOptions={getTemplate}
                  />
                )}
              </Form.Row>
              <Form.Row>
                <Form.Column>
                  <Form.Row>
                    <Input
                      id="idioma"
                      name="idioma"
                      label="Idioma"
                      type="text"
                      disabled
                    />
                    <Input
                      id="qtdeNivel"
                      name="qtdeNivel"
                      label="Qtde. de Níveis"
                      type="number"
                      disabled
                    />
                  </Form.Row>
                  <Form.Row>
                    <Select
                      id="iaTipoItemPagina"
                      name="iaTipoItemPagina"
                      label="Tipo Item Página"
                      isSearchable={false}
                      defaultValue={opTipoItemPagina[catalogo.iaTipoItemPagina]}
                      options={opTipoItemPagina}
                    />
                    <Select
                      id="iaTipoSequenciaPagina"
                      name="iaTipoSequenciaPagina"
                      label="Tipo Sequência Página"
                      isSearchable={false}
                      defaultValue={
                        opTipoSequenciaPagina[catalogo.iaTipoSequenciaPagina]
                      }
                      options={opTipoSequenciaPagina}
                    />
                  </Form.Row>
                  <Form.Column>
                    {nivelClassificacao.map((classificacao) => (
                      <Input
                        key={classificacao.nivel}
                        id={classificacao.id}
                        name={classificacao.name}
                        label={classificacao.label}
                        defaultValue={classificacao.descricao}
                        type="text"
                      />
                    ))}
                  </Form.Column>
                </Form.Column>
                <Form.Column>
                  <ImgFile
                    id="imagemUrl"
                    name="imagemUrl"
                    label="Imagem catálogo"
                    handleUpload={(e) => handleImagem(e)}
                    handleDelete={handleRemoverImagem}
                  />
                </Form.Column>
              </Form.Row>
            </Form.Column>
            <Form.Separate withFlex />
            <Form.Footer>
              <button
                id="btn-cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancelar
              </button>
              <button id="btn-submit" type="submit" form="formCatalogo">
                Confirmar
              </button>
            </Form.Footer>
          </Form>
        )}
      </FormWrapper>
    </>
  );
}

export default CatalogoEditar;
