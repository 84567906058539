import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  // MdImage,
} from 'react-icons/md';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';

import { usePrevious, useMeasure } from '../Helpers';

import { Container, Title, Content } from './styles';

function Tree({ children, name, searchParams, defaultOpen, defaultSelected }) {
  const [isOpen, setIsOpen] = useState(false);

  const previous = usePrevious(isOpen);
  const [bind, { height: viewHeight }] = useMeasure();

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const { height, opacity, transform } = useSpring({
    from: {
      height: 0,
      opacity: 0,
      // width: 'auto',
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      height: isOpen ? viewHeight : 0,
      // width: isOpen ? viewWidth : 'auto',
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
    },
  });

  const Icon =
    children && (isOpen ? MdKeyboardArrowDown : MdKeyboardArrowRight);

  return (
    <Container>
      <Title selected={defaultSelected}>
        {children ? (
          <>
            <Link
              to={(location) => ({ ...location, search: searchParams })}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Icon size={16} />
              {name}
            </Link>
          </>
        ) : (
          <Link
            to={(location) => ({ ...location, search: searchParams })}
            onClick={() => setIsOpen(true)}
          >
            {/* <MdImage size={18} /> */}
            {name}
          </Link>
        )}
      </Title>

      {children && (
        <Content
          style={{
            opacity,
            height: isOpen && previous === isOpen ? 'auto' : height,
            // height: isOpen ? 'auto' : height,
          }}
        >
          <animated.div style={{ transform }} {...bind}>
            {children}
          </animated.div>
        </Content>
      )}
    </Container>
  );
}

Tree.propTypes = {
  children: PropTypes.instanceOf(Object),
  name: PropTypes.string.isRequired,
  searchParams: PropTypes.string,
  defaultOpen: PropTypes.bool,
  defaultSelected: PropTypes.bool,
};

Tree.defaultProps = {
  children: null,
  searchParams: '',
  defaultOpen: false,
  defaultSelected: false,
};

export default Tree;
