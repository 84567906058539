import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

Container.Header = styled.div`
  background: #fff;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  /* height: 100%; */
  /* min-height: 58px; */
  padding: 5px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
`;

Container.Header.BtnBack = styled.button`
  background: none;
  border-radius: 10px;
  border: none;
  /* margin-left: 10px; */
  height: 30px;
  width: 30px;
  color: #2c405a;
  transition: all 1s;

  :hover {
    /* background: ${darken(0.05, '#fff')}; */
    background: ${darken(0.05, 'rgba(44, 64, 90, 0.05)')};
  }
`;

Container.Header.BtnFavorite = styled.button`
  background: none;
  border: 0;
  border-radius: 5px;
  color: #2c405a;
  height: 40px;
  width: 40px;
`;

export const WrapperTitleInfo = styled.div`
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  padding-right: 25px;
`;

export const WrapperSubtitles = styled.div`
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  /* padding: 0 10px; */
`;

export const WrapperTittleInfoSubtitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* flex: 1; */
  flex-direction: row;
  /* padding: 4px; */
`;

Container.Header.Title = styled.h1`
  width: auto;
  font-size: 14px;
  color: #2c405a;
`;

Container.Header.Subtitle = styled.span`
  font-size: 12px;
  padding-top: 2px;
  color: #2c405a;

  + span {
    padding-left: 5px;
  }
`;

Container.Header.Search = styled.div`
  /* padding: 2px 10px; */
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

Main.Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
`;

Main.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px 0;
  overflow-y: auto;
`;

export const BtnTreeView = styled.button`
  /* position: relative; */
  width: 35%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fff;
  color: #a7a7a7;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #0000000d;
  /* z-index: 5; */
`;

const Menu = styled.div`
  display: flex;
  box-shadow: 0px 3px 6px #0000000d;
  background: #fff;
  border-radius: 10px;
  height: calc(100vh - 180px);
  max-width: 100%;
  margin-right: 20px;
  z-index: 1;
`;

Menu.BtnCollapse = styled.button`
  background: none;
  border: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #a7a7a7;
  width: 18px;
  transform: background 1s;

  ${(props) =>
    props.collapse &&
    css`
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    `}

  :hover {
    background: #cad5f8;
    color: #fff;
  }
`;

Menu.Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  visibility: visible;

  /* oculta/exibe menu lateral */
  ${(props) =>
    props.hidden &&
    css`
      width: 0;
      padding: 0;
      visibility: hidden;
    `}
`;

Menu.Content.Filter = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  padding: 0 5px;

  > span {
    display: flex;
    align-items: center;
    border: 1px solid #cbd0d8;
    color: #444;
    height: 22px;
    border-radius: 5px;
    padding-left: 5px;
    margin: 2px 0;

    & + span {
      margin-left: 5px;
    }

    > button {
      height: 22px;
      width: 22px;
      color: #444;
      margin-left: 5px;
      background: none;
      border: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      transition: background 1s;

      :hover {
        border: 1px solid #cad5f8;
        background: #cad5f8;
        color: #fff;
      }
    }
  }
`;

Menu.Content.TreeView = styled.div`
  flex: 1;
  overflow: auto;

  padding: 5px 0 5px 2px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

Menu.Content.File = styled.div`
  margin-top: 20px;
  border-top: 1px solid #0000000d;
  padding: 20px 0;

  > h4 {
    font-size: 16px;
  }

  > ul {
    padding: 15px 0;

    > li {
      padding: 2px 0;

      > a {
        font-size: 14px;
        color: #2c405a;
        transform: color 1s;

        :hover {
          color: ${darken(0.05, '#2c405a')};
        }
      }
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

Content.BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
`;

Content.ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 5px;
`;

Content.ToolBar.Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #888888;
  font-size: 13px;
  padding: 5px;

  > h3 {
    color: #2c405a;
    font-size: 13px;
    padding: 5px 0;
  }

  > a {
    padding: 5px 0;
    font-size: 12px;
    transform: color 1s;

    :hover {
      color: ${darken(0.05, '#888888')};
      /* text-decoration: underline; */
    }
  }
`;

export const ContentToolBarHeaderButton = styled.button`
  width: 35%;
  height: 30px;
  border: none;
  background-color: #fff;
  color: #a7a7a7;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #0000000d;
`;

Content.ToolBar.DrawControl = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  > button,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 0; */
    border-radius: 10px;
    /* background: rgba(44, 64, 90, 0.05); */
    /* color: #2c405a; */
    height: 32px;
    width: 32px;
    /* border: 1px solid; */
    background: none;
    border: 0;
    color: #2c405a;
    transition: background 1s;

    + button {
      margin-left: 2px;
    }

    + span {
      width: none;
      margin-left: 5px;
      padding: 0 8px;
      white-space: nowrap;
    }

    :hover {
      background: ${darken(0.05, 'rgba(44, 64, 90, 0.05)')};
    }
  }

  /** separação entre ferramentas de navegação de páginas e redimensionamneto da imagem */
  button:nth-of-type(2) {
    margin-right: 5px;
  }
`;

export { Menu };
