import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { AppError } from '../../../errors/AppError';

import api from '../../../services/api';
import history from '../../../services/history';
import { CSV, opExport, opImport } from '../../../lib/csv-core';

import ActionMenu from '../../../components/ActionMenu';
import TitleBar from '../../../components/TitleBar';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';

import Pagination from '../../../components/EditorAI/Pagination';

import { Container, Wrapper } from './styles';

function CatalogoItem() {
  const location = useLocation();
  const { id } = location.state;

  const [itemPagina, setItemPagina] = useState([]);

  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaItemPaginaCatalogo = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`/catalogo/${id}/item`, {
        params: configFiltro,
      })
      .then((response) => {
        setItemPagina(
          response.data.itemPagina.map(({ quantidade, ...item }) => ({
            ...item,
            quantidade: quantidade.toFixed(4),
          }))
        );
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, id]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaItemPaginaCatalogo();
  }, [location, currentPage, carregaItemPaginaCatalogo]);

  const handleCriar = useCallback(() => {
    history.push('/catalogo/itens/criar', {
      background: location,
      idCatalogo: id,
    });
  }, [location, id]);

  const handleEditar = useCallback(
    (idPagina, idItem, idMaterial) => {
      history.push('/catalogo/itens/editar', {
        background: location,
        idCatalogo: id,
        idPagina,
        idItem,
        idMaterial,
      });
    },
    [location, id]
  );

  const handleVisualizar = useCallback(
    (e, idPagina, idItem, idMaterial) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD')
        handleEditar(idPagina, idItem, idMaterial);
    },
    [handleEditar]
  );

  const handleRemover = useCallback(
    async (idPagina, idItem, idMaterial) => {
      if (
        window.confirm(
          `Deseja realmente excluir o item da página? \n Catálogo: ${id} \n Página: ${idPagina} \n Item: ${idItem}`
        )
      ) {
        try {
          await api.delete(
            `catalogo/${id}/pagina/${idPagina}/item/${idItem}/${idMaterial}`
          );

          setItemPagina((state) =>
            state.filter(
              (item) => item.idItem !== idItem && item.idMaterial !== idMaterial
            )
          );
        } catch (err) {
          AppError(err);
        }
      }
    },
    [id]
  );

  const handleImportar = useCallback(() => {
    history.push('/catalogo/item/importar', {
      background: { ...location },
      parametros: { op: opImport.CATALOGO_ITEMPAGINA, idCatalogo: id },
    });
  }, [id, location]);

  const handleItemKit = useCallback(
    async (idCatalogo, idPagina, idItem, idMaterial) => {
      history.push(`/catalogo/conjunto`, {
        background: location,
        idCatalogo,
        idPagina,
        idItem,
        idMaterial,
      });
    },
    [location]
  );

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.CATALOGO_ITEMPAGINA, { idCatalogo: id });
  }, [id]);

  return (
    <Container>
      <TitleBar
        back
        title="Itens do catálogo"
        isSearchable
        onNew={handleCriar}
        onFileUpload={handleImportar}
        onFileDownload={handleExportar}
      />

      <Wrapper>
        <Table>
          <TableHeader
            withPadding
            titles={[
              { fieldname: 'idPagina', title: 'Página' },
              { fieldname: 'idItem', title: 'Item' },
              { fieldname: 'partnumber', title: 'Partnumber' },
              { fieldname: 'descricao', title: 'Descrição' },
              { fieldname: 'quantidade', title: 'Quantidade' },
              { fieldname: 'ordena', title: 'Ordena' },
              { fieldname: null, title: null },
            ]}
            cbSort={() => {}}
          />
          <tbody>
            {itemPagina.map((item, index) => (
              <RowMaster
                key={index}
                onClick={(e) =>
                  handleVisualizar(
                    e,
                    item.idPagina,
                    item.idItem,
                    item.materialFabricante.idMaterial
                  )
                }
              >
                <td>{item.idPagina}</td>
                <td>{item.idItem}</td>
                <td>{item.materialFabricante.partnumber}</td>
                <td>{item.materialFabricante.descricao}</td>
                <td>{item.quantidade}</td>
                <td>{item.ordena}</td>
                <td>
                  <ActionMenu
                    data={item}
                    onEdit={() =>
                      handleEditar(
                        item.idPagina,
                        item.idItem,
                        item.materialFabricante.idMaterial
                      )
                    }
                    onRemove={() =>
                      handleRemover(
                        item.idPagina,
                        item.idItem,
                        item.materialFabricante.idMaterial
                      )
                    }
                    onItemKit={() =>
                      handleItemKit(
                        item.idCatalogo,
                        item.idPagina,
                        item.idItem,
                        item.materialFabricante.idMaterial
                      )
                    }
                    onLineRemove={() =>
                      handleRemover(
                        item.idPagina,
                        item.idItem,
                        item.materialFabricante.idMaterial
                      )
                    }
                  />
                </td>
              </RowMaster>
            ))}
          </tbody>
        </Table>
      </Wrapper>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </Container>
  );
}

export default CatalogoItem;
