import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.search ? 'column' : 'row')};
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  padding: 10px 0;

  #btn-back {
    padding: 0 8px;
    margin-right: 10px;
    border-radius: 50px;
    color: #cbd0d8;
    border: none;
    background: none;
    transition: background 1s;
    min-height: 45px;

    :hover {
      background: ${darken(0.05, '#f2f5fc')};
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  flex-direction: column;

  & * {
    flex: 1;
    color: #2c405a;
    padding: 10px 0;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
`;

export const Subtitle = styled.strong`
  font-size: 14px;
`;

export const Action = styled.div`
  display: flex;
  align-items: center;

  > a {
    margin: 0 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 150px;
    text-align: center;

    color: #fff;
    font-weight: bold;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border: 0;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    height: 42px;
    width: 180px;
  }

  #btn-next,
  #btn-new {
    background: #ff6d3c;
    color: #fff;
    transition: background 1s;

    &:hover {
      background: ${darken(0.1, '#ff6d3c')};
    }
  }

  #btn-publish {
    background: #1063b0;
    color: #fff;
    transition: background 1s;

    &:hover {
      background: ${darken(0.1, '#1063B0')};
    }
  }

  #btn-back {
    background: #bbb;
    color: #fff;
    transition: background 1s;

    &:hover {
      background: ${darken(0.1, '#bbb')};
    }
  }

  #btn-submit {
    color: #fff;
    background: #538061;
    transition: background 1s;

    &:hover {
      background: ${darken(0.1, '#538061')};
    }
  }

  #btn-upload {
    background: none;
    color: #1789dd;
    font-weight: normal;
    transition: color 1s;

    &:hover {
      color: ${darken(0.1, '#1789dd')};
    }
  }

  #btn-excluir {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border: 0;
    border-radius: 5px;
    background-color: ${(props) => (props.isActive ? '#ff6d3c' : '#cbd0d8')};
    color: #fff;
    font-weight: bold;
    height: 42px;
    width: 180px;
  }

  .btn-img {
    background: none;
    padding: 0;
    min-width: 0;
    border: 0;
    color: #cbd0d8;
    transition: color 1s;

    :hover {
      color: #2c405a;
    }
  }
`;

Action.BtnDefault = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  height: 42px;
  width: 180px;
`;

Action.BtnSquare = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 5px;
  height: 42px;
  width: 50px !important;

  + button {
    margin-left: 15px;
  }

  &#btn-file-upload,
  &#btn-file-download,
  &#btn-file-print,
  &#btn-filter {
    background: none;
    color: #cbd0d8;
    transition: all 1s;

    &:hover {
      background: ${darken(0.1, '#f2f5fc')};
      color: ${darken(0.1, '#1789dd')};
    }
  }

  &#btn-nav-prior,
  &#btn-nav-next {
    background: none;
    color: #cbd0d8;
    transition: background 1s;

    &:hover {
      background: ${darken(0.05, '#f2f5fc')};
    }
  }
`;

export const NotificationWrapper = styled.div`
  position: relative;
`;

export const NotificationBadge = styled.span`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 12px;
  height: 12px;
  border: 1px solid #f2f5fc;
  border-radius: 50%;
  background: red;
  color: #ff474d;
`;
