import produce from 'immer';

const INITIAL_STATE = {
  items: [],
  order: {
    extCodiDiv2: null,
    extNumeOS: null,
    extItemOS: [],
    extObservacao: null,
    extUsuario: null,
    extExtra01: null,
    extExtra02: null,
    extExtra03: null,
    extExtra04: null,
  },
};

export default function cart(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@cart/ADD_SUCCESS': {
        const { item } = action;
        draft.items.push(item);
        break;
      }
      case '@cart/REMOVE': {
        const itemIndex = action.codimate
          ? draft.items.findIndex(
              (item) =>
                item.idCatalogo === action.idCatalogo &&
                item.idPagina === action.idPagina &&
                item.idItem === action.idItem &&
                item.materialFabricante.idMaterial === action.idMaterial &&
                item.materialEmpresa.codimate === action.codimate
            )
          : draft.items.findIndex(
              (item) =>
                item.idCatalogo === action.idCatalogo &&
                item.idPagina === action.idPagina &&
                item.idItem === action.idItem &&
                item.materialFabricante.idMaterial === action.idMaterial
            );

        if (itemIndex >= 0) {
          draft.items.splice(itemIndex, 1);
        }
        break;
      }
      case '@cart/UPDATE_AMOUNT_SUCCESS': {
        const itemIndex = action.codimate
          ? draft.items.findIndex(
              (item) =>
                item.idCatalogo === action.idCatalogo &&
                item.idPagina === action.idPagina &&
                item.idItem === action.idItem &&
                item.materialFabricante.idMaterial === action.idMaterial &&
                item.materialEmpresa.codimate === action.codimate
            )
          : draft.items.findIndex(
              (item) =>
                item.idCatalogo === action.idCatalogo &&
                item.idPagina === action.idPagina &&
                item.idItem === action.idItem &&
                item.materialFabricante.idMaterial === action.idMaterial
            );

        if (itemIndex >= 0) {
          draft.items[itemIndex].quantidadeTotal = action.quantidade;
        }
        break;
      }
      case '@cart/UPDATE_ITEMOS_SUCCESS': {
        const itemIndex = action.codimate
          ? draft.items.findIndex(
              (item) =>
                item.idCatalogo === action.idCatalogo &&
                item.idPagina === action.idPagina &&
                item.idItem === action.idItem &&
                item.materialFabricante.idMaterial === action.idMaterial &&
                item.materialEmpresa.codimate === action.codimate
            )
          : draft.items.findIndex(
              (item) =>
                item.idCatalogo === action.idCatalogo &&
                item.idPagina === action.idPagina &&
                item.idItem === action.idItem &&
                item.materialFabricante.idMaterial === action.idMaterial
            );

        if (itemIndex >= 0) {
          draft.items[itemIndex].itemOS = action.itemOS;
        }
        break;
      }
      case '@cart/CLEAR': {
        while (draft.items.length !== 0) {
          draft.items.pop();
        }
        draft.order = {
          extCodiDiv2: null,
          extNumeOS: null,
          extItemOS: [],
          extObservacao: null,
          extUsuario: null,
          extExtra01: null,
          extExtra02: null,
          extExtra03: null,
          extExtra04: null,
        };
        break;
      }
      case '@order/ADD_SUCCESS': {
        draft.order = action.payload.order;
        break;
      }
      case '@auth/SIGN_OUT': {
        while (draft.items.length !== 0) {
          draft.items.pop();
        }
        draft.order = {
          extCodiDiv2: null,
          extNumeOS: null,
          extItemOS: [],
          extObservacao: null,
          extUsuario: null,
          extExtra01: null,
          extExtra02: null,
          extExtra03: null,
          extExtra04: null,
        };
        break;
      }
      default:
    }
  });
}
