import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';

const schema = Yup.object().shape(
  {
    numeroLote: Yup.string().when(['partnumber', 'codiEqui'], {
      is: (partnumber, codiEqui) => !partnumber && !codiEqui,
      then: Yup.string().required(
        'É necessário preencher ao menos um campo de pesquisa'
      ),
    }),
    partnumber: Yup.string().when(['numeroLote', 'codiEqui'], {
      is: (numeroLote, codiEqui) => !numeroLote && !codiEqui,
      then: Yup.string().required(
        'É necessário preencher ao menos um campo de pesquisa'
      ),
    }),
    codiEqui: Yup.string().when(['numeroLote', 'partnumber'], {
      is: (numeroLote, partnumber) => !numeroLote && !partnumber,
      then: Yup.string().required(
        'É necessário preencher ao menos um campo de pesquisa'
      ),
    }),
  },
  [
    ['partnumber', 'codiEqui'],
    ['numeroLote', 'codiEqui'],
    ['numeroLote', 'partnumber'],
  ]
);

function HomePesquisar() {
  const formRef = useRef(null);

  const location = useLocation();

  const profile = useSelector((state) => state.user.profile);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { idEmpresa } = profile.empresa;

        const { numeroLote, partnumber, codiEqui } = data;

        const response = await api.get(
          `empresa-filtro/${idEmpresa}/serie-lote`,
          {
            params: { n: numeroLote, p: partnumber, e: codiEqui },
          }
        );

        if (!response.data) throw new AppError('Catálogo não encontrado');

        /** rediciona para o catálogo */
        const { idCatalogo } = response.data;
        history.push(`/catalogo.partes/${idCatalogo}`);
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [profile]
  );

  const handleCancelar = useCallback(() => {
    history.push('/catalogo.partes', {
      homePesquisar: true,
    });
  }, []);

  return (
    <>
      <TitleBar
        back={!!location.background}
        title="Pesquisa inicial"
        subtitle="Preencha pelo menos uma das informações para pesquisar o catálogo"
      />
      <FormWrapper>
        <Form
          id="formHomePesquisar"
          ref={formRef}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Row>
            <Form.Column>
              <Input id="numeroLote" name="numeroLote" label="Série / Lote" />
              <Input id="partnumber" name="partnumber" label="Partnumber" />
              <Input id="codiEqui" name="codiEqui" label="Cód. Equipamento" />
            </Form.Column>
          </Form.Row>
          <Form.Separate withFlex />
          <Form.Footer>
            <button id="btn-cancel" type="button" onClick={handleCancelar}>
              Cancelar
            </button>
            <button id="btn-submit" type="submit">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { HomePesquisar };
