import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Scope } from '@unform/core';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';

import { opTipoAcesso } from '../../../lib/const';
import { opEmpresa } from '../../../lib/inputOption';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import ImgFile from '../../../components/Form/Input/ImgFile';
import Select from '../../../components/Form/Input/Select';
import Checkbox from '../../../components/Form/Input/Checkbox';

/** validação dos campos */
const schema = Yup.object().shape({
  descricao: Yup.string().required('O campo "Descrição" deve ser preenchido'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('O campo "E-mail" deve ser preenchido'),
  qtdeUsuario: Yup.number()
    .typeError('O campo "Qtde Usuário" deve ser preenchido')
    .required('O campo "Qtde Usuário" deve ser preenchido'),
  tipo: Yup.number()
    .typeError('O campo "Tipo Empresa" deve ser preenchido')
    .required('O campo "Tipo Empresa" deve ser preenchido'),
});

function EmpresaEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { id } = location.state;

  const { adm } = useSelector((state) => state.user.profile);

  const [empresa, setEmpresa] = useState();
  const [tipoComplemento, setTipoComplemento] = useState([]);

  const carregarEmpresa = useCallback(() => {
    api.get(`empresa/${id}`).then((response) => {
      const { tipo } = response.data;

      formRef.current.setFieldValue('tipo', opEmpresa[tipo] || opEmpresa[0]);

      setEmpresa(response.data);
      setTipoComplemento(response.data.tipoComplemento);
    });
  }, [id]);

  useEffect(() => {
    carregarEmpresa();
  }, [carregarEmpresa]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        /** preenche informações extra quando as informações são editadas pela empresa */
        if (Number(adm) === opTipoAcesso.ADMIN_EMPRESA) {
          Object.assign(data, {
            qtdeUsuario: empresa.qtdeUsuario,
            tipo: empresa.tipo,
          });
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          descricao,
          email,
          qtdeUsuario,
          tipo,
          tipoComplemento: complemento,
        } = data;

        /** formata tipo complemento */
        const fmtTipoComplementoUsuario = complemento
          ? Object.entries(complemento).reduce(
              (arrComplemento, curComplemento) => {
                const [chave, valor] = curComplemento;

                /** retorna idComplemento dos itens selecionados */
                if (Number(valor) === 1) {
                  const [, idComplemento] = chave.split('-');
                  arrComplemento.push(Number(idComplemento));
                }

                return arrComplemento;
              },
              []
            )
          : null;

        await api.put(`empresa/${id}`, {
          descricao,
          email,
          qtdeUsuario,
          tipo: String(tipo),
          complemento: fmtTipoComplementoUsuario,
        });

        toast.success('Empresa atualizada com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id, adm, empresa]
  );

  const handleImagem = useCallback(
    async (data) => {
      try {
        if (data) {
          const { files = [] } = data.target || {};

          if (files.length > 0) {
            const formData = new FormData();
            formData.append('file', files[0]);

            await api.patch(`empresa/${id}`, formData);

            /** recarrega empresa */
            carregarEmpresa();

            /** mensagem de sucesso */
            toast.success('Imagem atualizada com sucesso!');
          }
        }
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id, carregarEmpresa]
  );

  const handleRemoverImagem = useCallback(async () => {
    try {
      await api.patch(`empresa/${id}`);

      /** recarrega empresa */
      carregarEmpresa();

      /** mensagem de sucesso */
      toast.success('Imagem atualizada com sucesso!');
    } catch (err) {
      AppError(err, formRef);
    }
  }, [id, carregarEmpresa]);

  return (
    <>
      <TitleBar back={!!location.background} title="Editar empresa" />

      <FormWrapper>
        <Form
          id="formEmpresa"
          ref={formRef}
          initialData={empresa}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Form.Column>
                {adm === opTipoAcesso.ADMIN_PLATAFORMA && (
                  <Form.Row>
                    <Input
                      id="idEmpresa"
                      name="idEmpresa"
                      label="Empresa"
                      type="text"
                      disabled
                    />
                    <Input
                      id="dataDesa"
                      name="dataDesa"
                      label="Data desativação"
                      type="text"
                      disabled
                    />
                  </Form.Row>
                )}
                <Input
                  id="descricao"
                  name="descricao"
                  label="Descrição"
                  type="text"
                />
                <Input id="email" name="email" label="E-mail" type="text" />
                {adm === opTipoAcesso.ADMIN_PLATAFORMA && (
                  <>
                    <Input
                      id="qtdeUsuario"
                      name="qtdeUsuario"
                      label="Qtde. Usuário"
                      type="number"
                    />
                    <Select
                      id="tipo"
                      name="tipo"
                      label="Tipo Empresa"
                      isSearchable={false}
                      defaultValue={opEmpresa[0]}
                      options={opEmpresa}
                    />

                    <h3>Informações complementares</h3>
                    <Form.Row>
                      <Scope path="tipoComplemento">
                        <Form.Grid>
                          {tipoComplemento.map((tico, idx) => (
                            <Checkbox
                              key={idx}
                              id={`tipoComplemento-${tico.idComplemento}`}
                              name={`tipoComplemento-${tico.idComplemento}`}
                              label={tico.descricao}
                              defaultChecked={tico.relacEmprComplemento}
                            />
                          ))}
                        </Form.Grid>
                      </Scope>
                    </Form.Row>
                  </>
                )}
              </Form.Column>
              <Form.Column>
                <ImgFile
                  id="url"
                  name="url"
                  label="Empresa"
                  width={200}
                  height={80}
                  handleUpload={(e) => handleImagem(e)}
                  handleDelete={handleRemoverImagem}
                />
              </Form.Column>
            </Form.Row>
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="formEmpresa">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { EmpresaEditar };
