import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { AppError } from '../../../errors/AppError';
import api from '../../../services/api';
import history from '../../../services/history';
import { CSV, opExport, opImport } from '../../../lib/csv-core';

import ActionMenu from '../../../components/ActionMenu';
import Pagination from '../../../components/EditorAI/Pagination';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import TitleBar from '../../../components/TitleBar';

function CatalogoNivel() {
  const location = useLocation();
  const { id } = location.state;

  const [niveis, setNiveis] = useState([]);

  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaNivel = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`catalogo/${id}/nivel`, {
        params: configFiltro,
      })
      .then((response) => {
        setNiveis(response.data.niveis);
        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, id]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaNivel();
  }, [location, currentPage, carregaNivel]);

  const handleCriar = useCallback(() => {
    history.push('/catalogo/nivel/criar', {
      background: location,
      idCatalogo: id,
    });
  }, [location, id]);

  const handleEditar = useCallback(
    (idNivel) => {
      history.push(`/catalogo/nivel/editar`, {
        background: location,
        idCatalogo: id,
        idNivel,
      });
    },
    [location, id]
  );

  const handleRemover = useCallback(
    async (idNivel) => {
      if (
        window.confirm(
          `Deseja realmente excluir o nível? Atenção: Caso exista, esse processo vai excluir o relacionamento desse nível com as páginas desse catálogo.`
        )
      ) {
        try {
          await api.delete(`catalogo/${id}/nivel/${idNivel}`);

          setNiveis((state) =>
            state.filter((nivel) => nivel.idNivel !== idNivel)
          );
        } catch (err) {
          AppError(err);
        }
      }
    },
    [id]
  );

  const handleVisualizar = useCallback(
    (e, idNivel) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') handleEditar(idNivel);
    },
    [handleEditar]
  );

  const handleImportar = useCallback(() => {
    history.push(`/catalogo/nivel/importar`, {
      background: { ...location },
      parametros: { op: opImport.CATALOGO_NIVEL, idCatalogo: id },
    });
  }, [id, location]);

  const handleExportar = useCallback(() => {
    CSV.criarExportacao(opExport.CATALOGO_NIVEL, { idCatalogo: id });
  }, [id]);

  return (
    <>
      <TitleBar
        back
        title="Níveis do catálogo"
        isSearchable
        onNew={handleCriar}
        onFileUpload={handleImportar}
        onFileDownload={handleExportar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'nivel', title: 'Nível' },
            { fieldname: 'codigo', title: 'Código' },
            { fieldname: 'descricaoBR', title: 'Descrição (BR)' },
            { fieldname: 'descricaoEN', title: 'Descrição (EN)' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {niveis.map((nivel) => (
            <RowMaster
              key={nivel.idNivel}
              onClick={(e) => handleVisualizar(e, nivel.idNivel)}
            >
              <td>{nivel.nivel}</td>
              <td>{nivel.codigo}</td>
              <td>{nivel.descricaoBR}</td>
              <td>{nivel.descricaoEN}</td>
              <td>
                <ActionMenu
                  data={nivel}
                  onEdit={() => handleEditar(nivel.idNivel)}
                  onRemove={() => handleRemover(nivel.idNivel)}
                  onLineRemove={() => handleRemover(nivel.idNivel)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export default CatalogoNivel;
