import { opTipoAcao } from '../../../lib/const';

export function signInRequest(login, password, returnTo = '') {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { login, password, returnTo },
  };
}

export function signInTokenRequest(
  token,
  action = opTipoAcao.INTEGRACAO_CATALOGO
) {
  return {
    type: '@auth/SIGN_IN_TOKEN_REQUEST',
    payload: { token, action },
  };
}

export function signInSuccess(
  token,
  user,
  app = false,
  action = opTipoAcao.PLATAFORMA
) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user, app, action },
  };
}

// export function signInTokenSuccess(token, user, app = false, action = 0) {
//   return {
//     type: '@auth/SIGN_IN_TOKEN_SUCCESS',
//     payload: { token, user, app, action },
//   };
// }

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}
