import { takeLatest, call, put, all } from 'redux-saga/effects';

import { signInSuccess, signFailure } from './actions';

import { AppError } from '../../../errors/AppError';
import history from '../../../services/history';
import api from '../../../services/api';

import { opTipoAcesso } from '../../../lib/const';

export function* signIn({ payload }) {
  try {
    const { login, password, returnTo } = payload;

    const response = yield call(api.post, 'session', {
      login,
      password,
    });

    const { token, user } = response.data;

    if (user && user.userId) user.adm = Number(user.adm);

    /** define o token do usuário autenticado para as requisições */
    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));

    /** verifica se o redirecionamento foi informado */
    if (returnTo) {
      history.push(returnTo);
      return;
    }

    /** verifica se é acesso de cliente */
    if (user.adm === opTipoAcesso.CLIENTE) {
      history.push('/catalogo.partes');
      return;
    }

    history.push('/catalogo');
  } catch (err) {
    AppError('Falha na autenticacação. Usuário ou senha inválidos');

    yield put(signFailure());
  }
}

export function* signInToken({ payload }) {
  try {
    const { token, action } = payload;

    const response = yield call(api.get, `app.session/${token}`);

    const { user } = response.data;

    if (user && user.userId) user.adm = Number(user.adm);

    /** define o token do usuário autenticado para as requisições */
    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user, true, action));
  } catch (err) {
    AppError('Falha na autenticacação. Token expirado.');

    yield put(signFailure());
  }
}

export function signOut() {
  history.push('/login');
}

export function* setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;
  if (token) {
    try {
      /** define o token do usuário autenticado para as requisições */
      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield call(api.get, `app.session/${token}`);
    } catch (err) {
      AppError(
        'Token expirado. Por favor, entre novamente com seu usuário e senha'
      );

      yield put(signFailure());

      history.push('/login');
    }
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_TOKEN_REQUEST', signInToken),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
