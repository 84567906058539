import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import api from '../../../services/api';
import history from '../../../services/history';

import TitleBar from '../../../components/TitleBar';
import { Table, RowMaster } from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import ActionMenu from '../../../components/ActionMenu';
import Pagination from '../../../components/EditorAI/Pagination';

function EmpresaNotificacao() {
  const location = useLocation();
  const { idEmpresa } = location.state;

  const [notificacoes, setNotificacoes] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaNotificacoes = useCallback(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`/empresa/${idEmpresa}/notificacao`, {
        params: configFiltro,
      })
      .then((response) => {
        setNotificacoes(
          response.data.notificacao.map((n) => ({
            ...n,
            instLido: n.instLido && format(parseISO(n.instLido), 'dd/MM/yyyy'),
          }))
        );

        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [idEmpresa, location]);

  const handleEditar = useCallback(
    (idNotificacao) => {
      history.push(`/empresa/notificacao/editar`, {
        background: location,
        id: idNotificacao,
        idEmpresa,
      });
    },
    [location, idEmpresa]
  );

  const handleVisualizar = useCallback(
    (e, idNotificacao) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idNotificacao);
      }
    },
    [handleEditar]
  );

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaNotificacoes();
  }, [location, currentPage, carregaNotificacoes]);

  return (
    <>
      <TitleBar back title="Notificações" isSearchable />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'idNotificacao', title: 'Notificação' },
            { fieldname: 'titulo', title: 'Título' },
            { fieldname: 'descricao', title: 'Descrição' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {notificacoes.map((notificacao) => (
            <RowMaster
              key={notificacao.idNotificacao}
              onClick={(e) => handleVisualizar(e, notificacao.idNotificacao)}
            >
              <td>{notificacao.idNotificacao}</td>
              <td>{notificacao.notificacao.titulo}</td>
              <td>{notificacao.notificacao.descricao}</td>
              <td>
                <ActionMenu
                  data={notificacao}
                  onEdit={() => handleEditar(notificacao.idNotificacao)}
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaNotificacao };
