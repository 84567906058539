import React, { useCallback } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MdShoppingCart,
  MdArrowDropDown,
  // MdNotifications,
  // MdPerson
} from 'react-icons/md';

import Logo from '../../assets/cdi.svg';

import history from '../../services/history';

import { signOut } from '../../store/modules/auth/actions';

import { opTipoAcesso } from '../../lib/const';

import ActionMenu from '../ActionMenu';

import { Container, Menu, User, CartMenu } from './styles';

function Header() {
  const { url } = useRouteMatch();
  const location = useLocation();

  const profile = useSelector((state) => {
    const { userId, name = '', adm, empresa } = state.user.profile || {};
    const { idEmpresa, url: logotipo } = empresa || {};

    return { userId, name, adm, idEmpresa, url: logotipo };
  });

  const { app, signed } = useSelector((state) => state.auth);

  /** verifica se existe itens no carrinho */
  const quantidadeItens = useSelector((state) => state.cart.items.length);

  const dispatch = useDispatch();

  // const handleLogin = useCallback(() => {
  //   history.push(
  //     `/login?return_to=${encodeURIComponent(window.location.pathname)}`
  //   );
  // }, []);

  // const handleSignin = useCallback(() => {
  //   history.push(
  //     `/signin?return_to=${encodeURIComponent(window.location.pathname)}`
  //   );
  // }, []);

  const handleLogout = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  const handleUsuarioPerfil = useCallback(() => {
    const { userId, adm, idEmpresa } = profile;

    switch (Number(adm)) {
      case opTipoAcesso.ADMIN_PLATAFORMA:
      case opTipoAcesso.ADMIN_EMPRESA:
      case opTipoAcesso.USUARIO:
        history.push('/empresa/usuario/perfil', {
          background: location,
          idEmpresa,
          id: userId,
        });
        break;
      case opTipoAcesso.CLIENTE:
        history.push('/usuario/perfil', {
          id: userId,
        });
        break;
      default:
        break;
    }
  }, [location, profile]);

  // const handleUsuarioTrocarSenha = useCallback(() => {
  //   history.push(`/usuario/trocar`, {
  //     background: location,
  //     id: userId,
  //   });
  // }, [location, userId]);

  const handleUsuarioSolicitacao = useCallback(() => {
    const { userId, adm, idEmpresa } = profile;

    switch (Number(adm)) {
      case opTipoAcesso.ADMIN_PLATAFORMA:
      case opTipoAcesso.ADMIN_EMPRESA:
      case opTipoAcesso.USUARIO:
        history.push('/empresa/solicitacao', {
          idEmpresa,
          id: userId,
        });
        break;
      case opTipoAcesso.CLIENTE:
        history.push('/usuario/solicitacao', {
          id: userId,
        });
        break;
      default:
        break;
    }
  }, [profile]);

  const handleCarrinho = useCallback(() => {
    history.push('/carrinho');
  }, []);

  return (
    <Container>
      <img
        src={profile.url ? profile.url : Logo}
        alt="CDI - Catálogo Eletrônico"
      />
      <ul>
        {[opTipoAcesso.ADMIN_PLATAFORMA].includes(Number(profile.adm)) && (
          <>
            <Menu selected={url.startsWith('/catalogo.partes')}>
              <Link to="/catalogo.partes">Home</Link>
            </Menu>
            <Menu
              selected={
                url.startsWith('/edicao') ||
                (url.startsWith('/catalogo') && !url.includes('.'))
              }
            >
              <Link to="/catalogo">Catálogos</Link>
            </Menu>
            <Menu selected={url.startsWith('/fabricante')}>
              <Link to="/fabricante">Fabricantes</Link>
            </Menu>
            <Menu selected={url.startsWith('/modelo')}>
              <Link to="/modelo">Modelos</Link>
            </Menu>
            <Menu selected={url.startsWith('/empresa')}>
              <Link to="/empresa">Empresas</Link>
            </Menu>
            {/* <Menu selected={url.startsWith('/usuario')}>
              <Link to="/usuario">Usuários</Link>
            </Menu> */}
          </>
        )}

        {!app &&
          [opTipoAcesso.ADMIN_EMPRESA, opTipoAcesso.CLIENTE].includes(
            Number(profile.adm)
          ) && (
            <Menu selected={url.startsWith('/catalogo.partes')}>
              <Link to="/catalogo.partes">Home</Link>
            </Menu>
          )}

        {!app && [opTipoAcesso.CLIENTE].includes(Number(profile.adm)) && (
          <Menu selected={url.startsWith('/usuario')}>
            <Link
              to={{
                pathname: '/usuario/detalhe',
                state: { id: profile.userId },
              }}
            >
              Minha Conta
            </Link>
          </Menu>
        )}

        {!app && [opTipoAcesso.ADMIN_EMPRESA].includes(Number(profile.adm)) && (
          <Menu
            selected={
              url.startsWith('/empresa') ||
              url.startsWith('/edicao') ||
              (url.startsWith('/catalogo') && !url.includes('.'))
            }
          >
            <Link
              to={{
                pathname: '/empresa/detalhe',
                state: { id: profile.idEmpresa },
              }}
            >
              Administrativo
            </Link>
          </Menu>
        )}
      </ul>

      <User>
        <CartMenu>
          {quantidadeItens !== 0 && <span>{quantidadeItens}</span>}
          <button type="button" title="Carrinho">
            <MdShoppingCart size={22} onClick={handleCarrinho} />
          </button>
        </CartMenu>
        {/* <CartMenu>
          {quantidadeItens !== 0 && <span>{quantidadeItens}</span>}
          <button type="button" title="Notificações">
            <MdNotifications size={22} onClick={() => {}} />
          </button>
        </CartMenu> */}

        {signed ? (
          <>
            <h4>{`Olá, ${profile.name.split(' ', 1)}`}</h4>
            {!app && (
              <ActionMenu
                Icon={MdArrowDropDown}
                onUserProfile={handleUsuarioPerfil}
                // onUserPassword={handleUsuarioTrocarSenha}
                onUserPurchase={handleUsuarioSolicitacao}
                onAppExit={handleLogout}
              />
            )}
          </>
        ) : (
          <>
            {/* <User.Login type="button" title="Login" onClick={handleLogin}>
              <MdPerson size={22} />
              <h5>Entrar</h5>
            </User.Login>
            <User.Signin type="button" title="SignIn" onClick={handleSignin}>
              <h5>Criar Conta</h5>
            </User.Signin> */}
          </>
        )}
      </User>
    </Container>
  );
}

export { Header };
