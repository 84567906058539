import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  MdWarning,
  MdUndo,
  MdRedo,
  MdRestore,
  MdOutlinePlaylistRemove,
  MdJoinRight,
} from 'react-icons/md';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// import jsonData from '../../../../lib/3.json';

import api from '../../../../services/api';

import { AppError } from '../../../../errors/AppError';

import { templateMapper } from '../../../../lib/template-core';
import { getTemplate } from '../../../../lib/asyncUtils';
import { opSimNao } from '../../../../lib/const';

import { Container } from '../../../../components/Container';

import TitleBar from '../../../../components/TitleBar';
import EditableTable from '../../../../components/Table/Editable';
import Modal from '../../../../components/Modal';

import { Form } from '../../../../components/Form';
import AsyncSelect from '../../../../components/Form/Input/AsyncSelect';

const C_IDITEM = 'idItem';
const C_PARTNUMBER = 'partnumber';
const C_DESCRICAO = 'descricao';
const C_QUANTIDADE = 'quantidade';
const C_NOTA = 'nota';
const C_ESPECTECNICA = 'especTecnica';
const C_ORDENA = 'ordena';

const INITIAL_MAPPER = [
  { value: C_IDITEM, label: 'Item' },
  { value: C_PARTNUMBER, label: 'Partnumber' },
  { value: C_DESCRICAO, label: 'Descrição' },
  { value: C_QUANTIDADE, label: 'Quantidade' },
  { value: C_NOTA, label: 'Nota' },
  { value: C_ESPECTECNICA, label: 'Especificação Técnica' },
  { value: C_ORDENA, label: 'Ordena' },
];

const HISTORY_OPERATIONS = {
  insert: 1,
  delete: -1,
  update: 0,
};

function EditorExtrairMapeamento({
  headers,
  data,
  template,
  onCancel,
  onMapping,
}) {
  // const modalRef = useRef(null);
  const formRef = useRef(null);

  const [originalData, setOriginalData] = useState({ headers: [], data: [] });
  const [tableData, setTableData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [tableHistory, setTableHistory] = useState([]);
  const [tableTemplate, setTableTemplate] = useState(null);
  const [historyStep, setHistoryStep] = useState(0);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [mensagemErro, setMensagemErro] = useState(null);

  useEffect(() => {
    setSkipPageReset(false);
  }, [tableData, tableColumn]);

  useEffect(() => {
    setOriginalData({ headers, data });
    setTableData(data);
    setTableColumn(headers);
    setTableHistory(() => {
      const historyData = data.map((item, index) => ({
        rowBefore: '',
        rowAfter: item,
        rowIndex: index,
      }));

      setHistoryStep(1);

      return [
        {
          action: 'insert',
          step: 1,
          rows: historyData,
        },
      ];
    });

    if (
      process.env.REACT_APP_EXPERIMENTAL_CATALOGO_TEMPLATE === opSimNao.SIM &&
      template
    ) {
      setTableTemplate({ autoApply: true, template });

      const { idTemplate, nome } = template;

      formRef.current.setFieldValue('template', {
        value: idTemplate,
        label: nome,
      });
    }
  }, [headers, data, template]);

  const handleSubmit = useCallback(async () => {
    /** ajusta dados para enviar */
    const submitData = tableData.map((lines) => {
      const entries = new Map(
        tableColumn
          .filter((column) => column.option)
          .map((column) => [column.option, lines[column.accessor] || null])
      );
      return Object.fromEntries(entries);
    });

    const schema = Yup.object().shape({
      idItem: Yup.string().required(
        '● Coluna "ITEM" deve ser determinada / preenchida'
      ),
      partnumber: Yup.string()
        .max(100, '● Coluna "PARTNUMBER" deve conter no máximo 100 caracteres')
        .required('● Coluna "PARTNUMBER" deve ser determinada / preenchida'),
      descricao: Yup.string()
        .max(120, '● Coluna "DESCRICAO" deve conter no máximo 120 caracteres')
        .required('● Coluna "DESCRICAO" deve ser determinada / preenchida'),
      quantidade: Yup.number().required(
        '● Coluna "QUANTIDADE" deve ser determinada / preenchida'
      ),
      nota: Yup.string()
        .nullable()
        .max(500, '● Coluna "NOTA" deve conter no máximo 500 caracteres'),
      especTecnica: Yup.string()
        .nullable()
        .max(
          500,
          '● Coluna "ESPEC TECNICA" deve conter no máximo 500 caracteres'
        ),
      ordena: Yup.number().required(
        '● Coluna "ORDENA" deve ser determinada / preenchida'
      ),
    });

    /** efetua validação dos campos */
    const pendenteValidacao = submitData.map(async (item, index) => {
      try {
        await schema.validate(item, {
          abortEarly: false,
        });

        return { ...item, valid: true };
      } catch ({ errors }) {
        return {
          ...item,
          valid: false,
          err: `Linha ${index}:\n${errors.join('\n')}`,
        };
      }
    });
    const eValido = await Promise.all(pendenteValidacao);

    const errosEncontrados = eValido
      .filter((item) => !item.valid)
      .map((item) => item.err)
      .join('\n');

    setMensagemErro(errosEncontrados);

    if (!errosEncontrados) {
      onMapping(eValido);
      onCancel(true);
    }
  }, [tableColumn, tableData, onMapping, onCancel]);

  /** cria e adiciona um objeto de histórico de alterações da tabela */
  const createHistoryStep = useCallback(
    (action, rows = [], cols = {}, all = false) => {
      setTableHistory((oldHistory) => {
        if (historyStep < oldHistory.length) {
          oldHistory.filter((item) => item.step <= historyStep);
        }

        const step = oldHistory.length + 1;

        setHistoryStep(step);

        return [...oldHistory, { action, rows, cols, all, step }];
      });
    },
    [historyStep]
  );

  /** retorna uma linha com valores vazios */
  const emptyRow = useCallback(() => {
    const row = { ...tableData[0] };

    Object.keys(row).forEach((col) => {
      row[col] = '';
    });

    return row;
  }, [tableData]);

  const handleApagaLinhasVazias = useCallback(() => {
    setSkipPageReset(true);

    setTableData((old) => {
      /** Loop pelas linhas da tabela */
      const newTableData = old.reduce((accRow, currRow) => {
        const { withValue } = Object.entries(currRow).reduce(
          /** Loop interno pelas colunas de cada linha */
          (accColumn, currColumn) => {
            const { option } = tableColumn.find(
              (a) => a.accessor === currColumn[0]
            );

            /** Verifica se a célula tem algum valor e que não seja da coluna 'Ordena' */
            if (String(currColumn[1]).trim() !== '' && option !== C_ORDENA) {
              accColumn.withValue += 1;
            }
            accColumn.total += 1;
            return accColumn;
          },
          { withValue: 0, total: 0 }
        );

        if (withValue > 0) {
          accRow.push(currRow);
        }

        return accRow;
      }, []);

      /** Monta array de linhas removidas da tabela */
      const historyData = old
        .map(
          (item, index) =>
            !newTableData.includes(item) && {
              rowBefore: item,
              rowAfter: emptyRow(),
              rowIndex: index,
            }
        )
        .filter((a) => a !== false);

      /** Adiciona ação no histórico de alterações */
      createHistoryStep('delete', historyData);

      return newTableData;
    });
  }, [tableColumn, createHistoryStep, emptyRow]);

  const handleHistoryStep = useCallback(
    // eslint-disable-next-line consistent-return
    (forwards = false) => {
      setSkipPageReset(true);

      /** Verifica se existe o passo desejado */
      if (
        (forwards && String(historyStep) === String(tableHistory.length)) || // não existe passo seguinte
        (!forwards && Number(historyStep) === 1) // não existe passo anterior
      ) {
        return window.alert('Não existem mais alterações.');
      }

      /** Define a operação à ser feita de acordo com a ação e direção do passo */
      /** Refazer => Busca o passo seguinte para realizar a ação */
      /** Desfazer => Busca o passo atual para realizar a ação inversa */
      const history = tableHistory[historyStep - (forwards ? 0 : 1)];
      const operation =
        Number(HISTORY_OPERATIONS[history.action]) * (forwards ? 1 : -1);

      switch (operation) {
        case HISTORY_OPERATIONS.insert:
          /** Insert */
          history.rows.forEach((row) => {
            setTableData((old) =>
              old.reduce((acc, crr, index) => {
                if (String(index) === String(row.rowIndex)) {
                  acc.push(forwards ? row.rowAfter : row.rowBefore);
                }

                acc.push(crr);
                return acc;
              }, [])
            );
          });

          break;
        case HISTORY_OPERATIONS.delete:
          /** Delete */
          history.rows.forEach((row) =>
            setTableData((old) =>
              old.filter((a) => a !== tableData[row.rowIndex])
            )
          );

          break;
        case HISTORY_OPERATIONS.update: {
          const { colsAfter = [], colsBefore = [] } = history.cols;
          const cols = forwards ? colsAfter : colsBefore;

          setTableColumn((prevState) => {
            if (cols.length > 0) {
              return cols;
            }

            return prevState;
          });

          if (history.all) {
            setTableData(
              history.rows.map((row) =>
                forwards ? row.rowAfter : row.rowBefore
              )
            );
          } else {
            /** Update */
            history.rows.forEach((row) =>
              setTableData((prevState) =>
                prevState.map((item, index) => {
                  if (String(index) === String(row.rowIndex)) {
                    return forwards ? row.rowAfter : row.rowBefore;
                  }
                  return item;
                })
              )
            );
          }

          break;
        }
        default:
          break;
      }

      /** Registra o passo */
      setHistoryStep((state) => (forwards ? state + 1 : state - 1));
    },
    [tableHistory, tableData, historyStep]
  );

  const handleResetTable = useCallback(() => {
    setTableHistory(() => {
      const historyData = originalData.data.map((item, index) => ({
        rowBefore: '',
        rowAfter: item,
        rowIndex: index,
      }));

      setHistoryStep(1);

      return [
        {
          action: 'insert',
          step: 1,
          rows: historyData,
        },
      ];
    });

    setTableData(originalData.data);
    setTableColumn(originalData.headers);
  }, [originalData]);

  const updateMyHeaderData = useCallback((formData) => {
    const [attribute] = Object.keys(formData);
    const [value] = Object.values(formData);

    setTableColumn((state) => {
      const index = state.findIndex((column) => column.accessor === attribute);

      if (index >= 0) state[index].option = value;

      return state;
    });
  }, []);

  const updateMyData = useCallback(
    (rowIndex, columnId, value, ignoreHistory = false) => {
      setSkipPageReset(true);

      /** Adiciona alterações no histórico */
      if (!ignoreHistory && tableData[rowIndex][columnId] !== value) {
        createHistoryStep('update', [
          {
            rowBefore: { ...tableData[rowIndex] },
            rowAfter: {
              ...tableData[rowIndex],
              [columnId]: value,
            },
            rowIndex,
          },
        ]);
      }

      /** Atualiza a tabela */
      setTableData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        })
      );
    },
    [tableData, createHistoryStep]
  );

  const deleteMyData = useCallback(
    (rowIndex) => {
      // if (
      //   window.confirm(
      //     `Deseja realmente remover a linha ${Number(rowIndex) + 1}?`
      //   )
      // ) {
      setSkipPageReset(true);

      /** Adiciona alterações no histórico */
      createHistoryStep('delete', [
        {
          rowBefore: { ...tableData[rowIndex] },
          rowAfter: emptyRow(),
          rowIndex,
        },
      ]);

      /** Atualiza a tabela */
      setTableData((state) =>
        state.filter((row) => row !== tableData[rowIndex])
      );
      // }
    },
    [tableData, createHistoryStep, emptyRow]
  );

  const insertNewRow = useCallback(
    (rowIndex) => {
      setSkipPageReset(true);

      /** Adiciona alterações no histórico */
      createHistoryStep('insert', [
        {
          rowBefore: { ...tableData[rowIndex] },
          rowAfter: emptyRow(),
          rowIndex,
        },
      ]);

      /** Atualiza a tabela */
      setTableData((old) =>
        old.reduce((acc, crr, index) => {
          if (String(index) === String(rowIndex)) {
            acc.push(emptyRow());
          }
          acc.push(crr);

          return acc;
        }, [])
      );
    },
    [tableData, createHistoryStep, emptyRow]
  );

  const updateMyHistory = useCallback(
    (rows, cols = {}, all = false) => {
      createHistoryStep('update', rows, cols, all);
    },
    [createHistoryStep]
  );

  const deleteMyColumn = useCallback(
    (id) => {
      /** altera headers */
      const fmtColumn = tableColumn.filter((column) => column.accessor !== id);
      let fmtData = Array.from(tableData);

      /** altera dados da tabela */
      fmtData = fmtData.map(({ [id]: _, ...d1 }) => d1);

      const columnHistory = {
        colsBefore: tableColumn,
        colsAfter: fmtColumn,
      };

      const rowHistory = fmtData.map((d1, idx) => ({
        rowBefore: tableData[idx],
        rowAfter: d1,
        rowIndex: idx,
      }));

      setTableColumn([...fmtColumn]);
      setTableData([...fmtData]);

      /** adiciona alterações no histórico */
      updateMyHistory(rowHistory, columnHistory);
    },
    [updateMyHistory, tableColumn, tableData]
  );

  const insertNewCol = useCallback(
    (accessor) => {
      const fmtColumn = Array.from(tableColumn);
      let fmtData = Array.from(tableData);

      const position = tableColumn.findIndex((c) => c.accessor === accessor);

      fmtColumn.splice(position, 0, {
        Header: `Coluna ${position}`,
        accessor: `col${position}`,
        option: null,
      });

      fmtColumn.forEach((h1, idx1) => {
        h1.Header = `Coluna ${idx1}`;
        h1.accessor = `col${idx1}`;
      });

      /** cria campo para os dados */
      fmtData = fmtData.map((d1) => {
        const curData = Object.entries(d1);

        curData.splice(position, 0, [`col${position}`, '']);

        curData.forEach((d2, idx2) => {
          d2[0] = `col${idx2}`;
        });

        return Object.fromEntries(curData);
      });

      const columnHistory = {
        colsBefore: tableColumn,
        colsAfter: fmtColumn,
      };

      const rowHistory = fmtData.map((d1, idx) => ({
        rowBefore: tableData[idx],
        rowAfter: d1,
        rowIndex: idx,
      }));

      setTableColumn([...fmtColumn]);
      setTableData([...fmtData]);

      /** adiciona alterações no histórico */
      updateMyHistory(rowHistory, columnHistory);
    },
    [tableColumn, tableData, updateMyHistory]
  );

  const handleTemplate = useCallback(() => {
    setSkipPageReset(true);

    try {
      /** converte esquema para objeto */
      const { esquema } = tableTemplate?.template || {};

      if (!esquema) {
        throw new Error('Esquema do template não encontrado!');
      }

      const fmtTemplate = JSON.parse(esquema);

      // const fmtTemplate = jsonData;

      const { columns = [] } = fmtTemplate;

      /** validação de colunas */
      const fmtTemplateShema = columns.filter((d1) => d1.created);

      if (tableColumn.length !== fmtTemplateShema.length) {
        throw new Error(
          `Quantidade selecionada de colunas é diferente da esperada pelo template! \n Selecionada: ${tableColumn.length} \n Esperada: ${fmtTemplateShema.length}`
        );
      }

      /** processa template */
      const [fmtColumn, fmtData, rowHistory] = templateMapper.execute({
        template: fmtTemplate,
        tbColumn: tableColumn,
        tbData: tableData,
      });

      const columnHistory = {
        colsBefore: tableColumn,
        colsAfter: fmtColumn,
      };

      /** adiciona alterações no histórico */
      updateMyHistory(rowHistory, columnHistory, true);

      /** atualiza informações */
      setTableColumn([...fmtColumn]);
      setTableData([...fmtData]);
    } catch (err) {
      // console.log(err);
      AppError(err);
    }
  }, [tableData, tableColumn, tableTemplate, updateMyHistory]);

  const handleTemplateChange = useCallback(
    async (e) => {
      const { value } = e || {};
      /** verifica se existe template selecionado ou template padrão */
      const { idTemplate } = tableTemplate || {};

      try {
        /** limpa template */
        if (!value) {
          setTableTemplate(null);
          return;
        }

        /** consulta novo valor para atualizar */
        if (idTemplate !== value) {
          const response = await api.get(`template-catalogo/${value}`);

          const { nome, esquema } = response.data;

          setTableTemplate({
            autoApply: false,
            template: {
              idTemplate: value,
              nome,
              esquema,
            },
          });
        }
      } catch (err) {
        throw new AppError(err);
      }
    },
    [tableTemplate]
  );

  useEffect(
    () => {
      /** aplica automaticamente o template */
      if (tableTemplate?.autoApply) handleTemplate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableTemplate]
  );

  return (
    <Modal
      // innerRef={modalRef}
      width={95}
      height={90}
      onClose={onCancel}
    >
      <TitleBar back={false} title="Mapeamento dos dados da tabela" />

      <Container.Wrapper>
        {tableColumn && tableData && (
          <EditableTable
            columns={tableColumn}
            data={tableData}
            headers={INITIAL_MAPPER}
            updateMyHeaderData={updateMyHeaderData}
            updateMyData={updateMyData}
            deleteMyData={deleteMyData}
            insertNewRow={insertNewRow}
            insertMyColumn={insertNewCol}
            deleteMyColumn={deleteMyColumn}
            historyUpdate={updateMyHistory}
            skipPageReset={skipPageReset}
          />
        )}
      </Container.Wrapper>

      {mensagemErro && (
        <MdWarning size={30} color="#dd0312" title={mensagemErro} />
      )}

      <Container.Note>
        Obs.: Utilize o @ para geração automática para os campos ITEM e
        PARTNUMBER
      </Container.Note>
      <Container.Footer withSpaceBetween withSeparate>
        <Container.HistoryControl>
          <button
            id="btn-undo"
            title="Desfazer"
            type="button"
            onClick={() => handleHistoryStep()}
          >
            <MdUndo size={20} />
          </button>
          <button
            id="btn-redo"
            title="Refazer"
            type="button"
            onClick={() => handleHistoryStep('forwards')}
          >
            <MdRedo size={20} />
          </button>
          <button
            id="btn-empty"
            title="Apagar linhas vazias"
            type="button"
            onClick={handleApagaLinhasVazias}
          >
            <MdOutlinePlaylistRemove size={20} />
          </button>
          <button
            id="btn-reset"
            title="Desfazer TODAS as alterações"
            type="button"
            onClick={handleResetTable}
          >
            <MdRestore size={16} />
          </button>
          {process.env.REACT_APP_EXPERIMENTAL_CATALOGO_TEMPLATE ===
            opSimNao.SIM && (
            <Container.TemplateControl>
              <Form ref={formRef} autoComplete="off">
                <Form.Row alignCenter>
                  <AsyncSelect
                    id="template"
                    name="template"
                    placeholder="Selecione um template..."
                    isClearable
                    cacheOptions
                    defaultOptions
                    loadOptions={getTemplate}
                    onChange={(e) => handleTemplateChange(e)}
                    width={300}
                    menuPosition="fixed"
                  />
                  <button
                    title="Aplicar template"
                    type="button"
                    onClick={handleTemplate}
                  >
                    <MdJoinRight size={20} />
                  </button>
                </Form.Row>
              </Form>
            </Container.TemplateControl>
          )}
        </Container.HistoryControl>
        <Container.FormControl>
          <button id="btn-cancel" type="button" onClick={onCancel}>
            Cancelar
          </button>
          <button id="btn-submit" type="button" onClick={handleSubmit}>
            Confirmar
          </button>
        </Container.FormControl>
      </Container.Footer>
    </Modal>
  );
}

EditorExtrairMapeamento.propTypes = {
  headers: PropTypes.arrayOf(Object).isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  template: PropTypes.instanceOf(Object),
  onCancel: PropTypes.func.isRequired,
  onMapping: PropTypes.func.isRequired,
};

EditorExtrairMapeamento.defaultProps = {
  template: null,
};

export default EditorExtrairMapeamento;
