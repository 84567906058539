import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #f2f5fc;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 2000px;
  padding: 0;
  /* overflow: auto; */

  ${(props) =>
    props.withPadding &&
    css`
      padding: 20px 40px;
    `}
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: ${(props) => (props.isMobile ? '2px 10px 0' : '5px 0 0')};
  background: none;
  margin: auto 0 0;

  > a {
    > img {
      height: auto;
      width: ${(props) => (props.isMobile ? '100px' : '150px')};
      opacity: 0.8;
    }
  }
`;

export const MobileWrapper = styled.div`
  display: grid;
  grid-template-rows: 60px 1fr;
  background: #f2f5fc;
  width: 100%;
  height: 100vh;
`;

export const MobileContent = styled.div`
  display: grid;
  grid-template-rows: 1fr 60px;
  box-shadow: inset 0 0 1em #00000026;

  width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.withPadding &&
    css`
      padding: 5px;
    `}
`;

export const MobileFooter = styled.footer`
  display: flex;
  padding: 10px;
  background: none;
  align-items: center;
  justify-content: center;
  height: 60px;

  > a {
    > img {
      height: auto;
      width: 150px;
      opacity: 0.8;
    }
  }
`;
