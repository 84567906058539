import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* border-radius: 10px; */

  display: flex;
  align-items: center;
  justify-content: center;

  /** mantem o modal genérico sempre no */
  z-index: 99;
`;

export const Container = styled.div`
  background: #fff;
  position: relative;
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #00000033;
  flex-direction: column;
  padding: 10px 30px;
  height: auto;
  width: auto;
  overflow: auto;

  @media (max-width: 600px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ${(props) =>
    props.height &&
    css`
      height: ${`${props.height}%`};
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${`${props.width}%`};
    `}

  button#close {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee;
    border: 1px solid #eee;
    border-radius: 25px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;
    height: 25px;
    width: 25px;

    &:hover {
      background: ${darken(0.05, '#eee')};
    }
  }
`;
