import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';

import api from '../../../services/api';
import history from '../../../services/history';

import { AppError } from '../../../errors/AppError';
import { opNotificacaoTipo } from '../../../lib/const';

import TitleBar from '../../../components/TitleBar';
import { FormWrapper, Form } from '../../../components/Form';
import Input from '../../../components/Form/Input';
import InputViewer from '../../../components/Form/Input/Viewer';
import TextArea from '../../../components/Form/Input/TextArea';

/** validação dos campos */
const schema = Yup.object().shape({
  titulo: Yup.string().required('O campo "Título" deve ser preenchido'),
  descricao: Yup.string().required('O campo "Descrição" deve ser preenchido'),
  dataProg: Yup.date()
    .typeError('O campo "Data de Programação" deve ser preenchido')
    .required('O campo "Data de Programação" deve ser preenchido'),
  dataExp: Yup.date()
    .typeError('O campo "Data de Expiração" deve ser preenchido')
    .required('O campo "Data de Expiração" deve ser preenchido'),
});

function NotificacaoEditar() {
  const formRef = useRef(null);

  const location = useLocation();
  const { id } = location.state;

  const [notificacao, setNotificacao] = useState(null);

  useEffect(() => {
    api.get(`/notificacao/${id}`).then((response) => {
      const { instInc, dataProg, dataExp, notificacaoEmpresa } = response.data;

      let fmtNotificacaoEmpresa = notificacaoEmpresa;
      if (fmtNotificacaoEmpresa) {
        fmtNotificacaoEmpresa = {
          ...fmtNotificacaoEmpresa,
          instLido:
            fmtNotificacaoEmpresa.instLido &&
            format(
              parseISO(fmtNotificacaoEmpresa.instLido),
              'dd/MM/yyyy HH:mm:ss'
            ),
        };
      }

      setNotificacao({
        ...response.data,
        instInc: instInc && format(parseISO(instInc), 'yyyy-MM-dd'),
        dataProg: dataProg && format(parseISO(dataProg), 'yyyy-MM-dd'),
        dataExp: dataExp && format(parseISO(dataExp), 'yyyy-MM-dd'),
        notificacaoEmpresa: fmtNotificacaoEmpresa,
      });
    });
  }, [id]);

  const handleConfirmar = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { titulo, descricao, dataProg, dataExp } = data;

        await api.put(`/notificacao/${id}`, {
          titulo,
          descricao,
          dataProg,
          dataExp,
        });

        toast.success('Notificação atualizada com sucesso!');

        history.goBack();
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [id]
  );

  return (
    <>
      <TitleBar back={!!location.background} title="Alterar Notificação" />
      <FormWrapper>
        <Form
          id="form-ref"
          ref={formRef}
          schema={schema}
          initialData={notificacao}
          onSubmit={handleConfirmar}
          autoComplete="off"
        >
          <Form.Column>
            <Form.Row>
              <Input
                id="titulo"
                name="titulo"
                label="Título"
                disabled={
                  notificacao && notificacao.tipo === opNotificacaoTipo.PRIVADO
                }
              />
            </Form.Row>
            <Form.Row>
              <TextArea
                id="descricao"
                name="descricao"
                label="Descrição"
                rows="5"
                disabled={
                  notificacao && notificacao.tipo === opNotificacaoTipo.PRIVADO
                }
              />
            </Form.Row>
            <Form.Row>
              <Input
                id="dataProg"
                name="dataProg"
                label="Data de Programação"
                type="date"
                width={225}
                disabled={
                  notificacao && notificacao.tipo === opNotificacaoTipo.PRIVADO
                }
              />
              <Input
                id="dataExp"
                name="dataExp"
                label="Data de Expiração"
                type="date"
                width={225}
                disabled={
                  notificacao && notificacao.tipo === opNotificacaoTipo.PRIVADO
                }
              />
            </Form.Row>
            {notificacao && notificacao.tipo === opNotificacaoTipo.PRIVADO && (
              <>
                <h3>Informações da empresa</h3>
                <Form.Row>
                  <InputViewer
                    id="empresa"
                    name="notificacaoEmpresa.empresa.descricao"
                    label="Empresa"
                  />
                  <InputViewer
                    id="lidoPor"
                    name="notificacaoEmpresa.usuario.nome"
                    label="Lido Por"
                  />
                  <InputViewer
                    id="lidoEm"
                    name="notificacaoEmpresa.instLido"
                    label="Lido Em"
                  />
                </Form.Row>
              </>
            )}
          </Form.Column>
          <Form.Separate withFlex />
          <Form.Footer>
            <button
              id="btn-cancel"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button id="btn-submit" type="submit" form="form-ref">
              Confirmar
            </button>
          </Form.Footer>
        </Form>
      </FormWrapper>
    </>
  );
}

export { NotificacaoEditar };
