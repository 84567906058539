import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import param from './param/sagas';
import cart from './cart/sagas';

/** combina os sagas */
export default function* rootSaga() {
  return yield all([auth, user, param, cart]);
}
