import styled from 'styled-components';

export const WrapperPopup = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000008c;
  z-index: 100;
`;

export const WrapperItem = styled.div`
  display: flex;
  width: 100%;
  min-height: 250px;
  max-width: 425px;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 10px;

  color: #2c405a;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 25px;
  position: relative;
`;

Content.BtnClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px;
  height: 30px;
  width: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #cbd0d8;
  border: 0;
  background: none;
`;

Content.BtnAddCart = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff6d3c;
  border: 0;
  border-radius: 5px;
  color: #fff;
  height: 40px;

  > svg {
    margin-right: 5px;
  }
`;

Content.Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #2c405a;

  user-select: text;

  > strong {
    font-size: 16px;
  }
`;

Content.Title.Espec = styled.span`
  color: rgba(44, 64, 90, 0.5);
  font-style: italic;
`;

Content.Form = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 20;

  > form {
    flex: 1;
    font-size: 14px;
    color: #2c405a;

    label {
      font-size: 12px;
      color: #2c405a;
      font-weight: bolder;
      text-transform: uppercase;
    }

    > strong {
      font-size: 16px;
    }

    > p {
      border-radius: 5px;
      background: #f8f8f8;
      padding: 5px 10px;
      color: rgba(44, 64, 90, 0.5);
      text-align: center;
      font-size: 12px;
      margin-top: 5px;
    }
  }
`;

Content.Form.Error = styled.span`
  width: 100%;
  text-align: center;
  color: #e95938;
  font-style: italic;
  font-size: 12px;
`;

Content.Form.Quantity = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
    flex: 1;
    justify-content: center;

    input {
      text-align: center;
    }

    > button {
      border: 0;
      background: none;
      margin: 0 10px;
      padding: 5px 10px;
    }
  }
`;
