import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { InputWrapper } from '..';

function Input({
  name,
  label,
  width,
  flexDirection,
  isHidden,
  customPagination,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <InputWrapper
      width={width}
      isHidden={isHidden}
      flexDirection={flexDirection}
      customPagination={customPagination}
    >
      {label && <label htmlFor={fieldName}>{label}</label>}

      <input
        ref={inputRef}
        className={error ? 'has-error' : ''}
        defaultValue={defaultValue}
        {...rest}
      />

      {error && <span className="error">{error}</span>}
    </InputWrapper>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
  flexDirection: PropTypes.string,
  isHidden: PropTypes.bool,
  customPagination: PropTypes.bool,
};

Input.defaultProps = {
  label: null,
  width: null,
  flexDirection: 'column',
  isHidden: false,
  customPagination: false,
};

export default Input;
