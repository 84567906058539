import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  MdChevronLeft,
  MdChevronRight,
  MdCloudUpload,
  MdOutlineFileDownload,
  MdPrint,
  MdFilterAlt,
} from 'react-icons/md';
import PropTypes from 'prop-types';

import { Form } from '../Form';
import DebounceInput from '../Form/Input/Debounce';

import history from '../../services/history';

import {
  Container,
  Wrapper,
  TitleWrapper,
  Title,
  Subtitle,
  Action,
  NotificationWrapper,
  NotificationBadge,
} from './styles';

function TitleBar({
  title,
  subtitle,
  back,
  width,
  withNavigation,
  isSearchable,
  filterSearchType,
  onNew,
  onFileUpload,
  onDelete,
  onBtnDeletar,
  isActive,
  onFileDownload,
  onFilePrint,
  onPrevNav,
  onNextNav,
  onBack,
  children,
}) {
  const formRef = useRef();

  const location = useLocation();
  const [filterBagdeIndicator, setFilterBadgeIndicator] = useState(false);

  const ActionWrapper = () => (
    <>
      {onFileUpload && (
        <Action.BtnSquare
          type="button"
          title="Carregar dados"
          id="btn-file-upload"
          onClick={onFileUpload}
        >
          <MdCloudUpload size={30} />
        </Action.BtnSquare>
      )}

      {onBtnDeletar && (
        <Action
          type="button"
          id="btn-excluir"
          onClick={() => onDelete()}
          isActive={isActive}
        >
          Excluir
        </Action>
      )}

      {onFileDownload && (
        <Action.BtnSquare
          type="button"
          id="btn-file-download"
          title="Exportar dados"
          onClick={() => onFileDownload()}
        >
          <MdOutlineFileDownload size={30} />
        </Action.BtnSquare>
      )}

      {onFilePrint && (
        <Action.BtnSquare
          type="button"
          id="btn-file-print"
          title="Imprimir dados"
          onClick={() => onFilePrint()}
        >
          <MdPrint size={30} />
        </Action.BtnSquare>
      )}

      {onNew && (
        <Action.BtnDefault type="button" id="btn-new" onClick={() => onNew()}>
          Novo
        </Action.BtnDefault>
      )}

      {withNavigation && (
        <>
          <Action.BtnSquare
            type="button"
            id="btn-nav-prior"
            onClick={() => onPrevNav && onPrevNav()}
          >
            <MdChevronLeft size={30} />
          </Action.BtnSquare>
          <Action.BtnSquare
            type="button"
            id="btn-nav-next"
            onClick={() => onNextNav && onNextNav()}
          >
            <MdChevronRight size={30} />
          </Action.BtnSquare>
        </>
      )}
    </>
  );

  useEffect(
    () => {
      /** pega campos já informados na query */
      const curSearch = Object.fromEntries(
        new URLSearchParams(location.search) || []
      );

      const { q: searchQuery = '' } = curSearch || {};

      if (formRef.current) formRef.current.setData({ searchQuery });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    /** pega campos já informados na query */
    const curSearch = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );

    /** verifica parâmetros para preencher indicador */
    let curParamCount = 0;
    Object.keys(curSearch).forEach((key) => {
      if (!['q', 'page'].includes(key)) curParamCount += 1;
    });

    setFilterBadgeIndicator(curParamCount > 0);
  }, [location]);

  const handleSearch = useCallback(
    (data) => {
      /** pega campos já informados na query */
      const curSearch = Object.fromEntries(
        new URLSearchParams(location.search) || []
      );

      Object.assign(curSearch, data);

      /** remove campos sem preenchimento */
      Object.keys(curSearch).forEach((key) => {
        if (
          !curSearch[key] ||
          (Array.isArray(curSearch[key]) && !curSearch[key].length)
        )
          delete curSearch[key];
      });

      const accSearch = Object.keys(curSearch)
        .map((key) => `${key}=${encodeURI(curSearch[key])}`)
        .join('&');

      if (`?${accSearch}` !== location.search) {
        history.replace(`${location.pathname}?${accSearch}`, location.state);
      }
    },
    [location]
  );

  const handleFiltroPesquisa = useCallback(() => {
    history.replace(`${location.pathname}/filtro-pesquisa`, {
      background: location,
      filterSearchType,
    });
  }, [location, filterSearchType]);

  return (
    <Container search={isSearchable} width={width}>
      <Wrapper>
        {back && (
          <button
            type="button"
            id="btn-back"
            onClick={onBack || (() => history.goBack())}
          >
            <MdChevronLeft size={30} />
          </button>
        )}

        <TitleWrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TitleWrapper>
      </Wrapper>

      {isSearchable ? (
        <>
          <Wrapper>
            <Form autoComplete="off" ref={formRef}>
              <Form.Row alignCenter>
                <DebounceInput
                  id="searchQuery"
                  name="searchQuery"
                  type="text"
                  placeholder="Digite para pesquisar..."
                  debounceTimeout={600}
                  width={300}
                  onChange={(e) => {
                    e.preventDefault();
                    handleSearch({ q: e.target.value || '' });
                  }}
                />

                {filterSearchType && (
                  <Action.BtnSquare
                    type="button"
                    id="btn-filter"
                    title="Filtro de pesquisa"
                    onClick={handleFiltroPesquisa}
                  >
                    <NotificationWrapper>
                      {filterBagdeIndicator && <NotificationBadge />}
                      <MdFilterAlt size={30} />
                    </NotificationWrapper>
                  </Action.BtnSquare>
                )}
              </Form.Row>
            </Form>

            <Action>
              <ActionWrapper />
              {children}
            </Action>
          </Wrapper>
        </>
      ) : (
        <Action>
          <ActionWrapper />
          {children}
        </Action>
      )}
    </Container>
  );
}

TitleBar.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  back: PropTypes.bool,
  width: PropTypes.number,
  withNavigation: PropTypes.bool,
  isActive: PropTypes.bool,
  isSearchable: PropTypes.bool,
  filterSearchType: PropTypes.string,
  onNew: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onFileUpload: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onDelete: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onBtnDeletar: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onFileDownload: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onFilePrint: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onPrevNav: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onNextNav: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onBack: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

TitleBar.defaultProps = {
  title: '',
  subtitle: '',
  back: false,
  width: null,
  withNavigation: false,
  isActive: false,
  isSearchable: false,
  filterSearchType: '',
  onNew: null,
  onFileUpload: null,
  onDelete: null,
  onBtnDeletar: null,
  onFileDownload: null,
  onFilePrint: null,
  onPrevNav: null,
  onNextNav: null,
  onBack: null,
  children: null,
};

export default TitleBar;
