import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    
    /** scrollbar para firefox */
    scrollbar-width: thin;
  }
  
  *:focus {
    outline: 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px grey;     
  }

  ::-webkit-scrollbar-thumb {
    background: #79869e;     
  }

  html, body, #app-root {
    display: flex;
    height: 100%;         
    max-height: 100vh;
    width: 100%;  

    /* Desabilita a seleção de texto em toda a plataforma */
    user-select: none;  
  }
  
  body {
    -webkit-font-smoothing: antialiased;
  }
  
  body, input, textarea, button {
    font: 14px 'Roboto', sans-serif;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #2C405A;
    font-family: 'Rubik', sans-serif;    
  }
  
  a {
    text-decoration: none;
  }
  
  ul {
    list-style: none;
  }
  
  button {
    cursor: pointer;
  }
`;
