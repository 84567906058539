const opSimNao = {
  SIM: 'S',
  NAO: 'N',
};

const opAtivoInativo = {
  TUDO: 0,
  ATIVO: 1,
  INATIVO: 2,
};

const opLayout = {
  DEFAULT: 0,
  MODAL: 1,
  AUTH_LOGIN: 2,
  AUTH_SIGNIN: 3,
  AUTH_FORGOT: 4,
};

export const opTipoAcesso = {
  USUARIO: 0,
  ADMIN_PLATAFORMA: 1,
  ADMIN_EMPRESA: 2,
  CLIENTE: 3,
  TOKEN: 4,
};

export const opTipoDocumento = {
  CPF: 0,
  CNPJ: 1,
};

export const opMascaraDocumento = {
  [opTipoDocumento.CPF]: '000.000.000-00',
  [opTipoDocumento.CNPJ]: '00.000.000/0000-00',
};

const opTipoAcao = {
  INTEGRACAO_CATALOGO: 0,
  INTEGRACAO_CONSULTA: 1,
  INTEGRACAO_SOLICITACAO: 2,
  INTEGRACAO_TOKEN: 3,
  PLATAFORMA: 9,
};

/** Empresa */
const opTipoEmpresa = {
  SERVICOS: 0,
  LOCADORA: 1,
  FABRICANTE: 2,
  ADMIN_PLATAFORMA: 3,
};

/** Catalogo */
const conCatalogoSituacao = {
  EM_DESENVOLVIMENTO: 0,
  AGUARDANDO_APROVACAO: 1,
  PUBLICADO: 2,
};

const opCatalogoTipoConversao = {
  MANUAL: 0,
  INTELIGENCIA_ARTIFICIAL: 1,
};

const opCatalogoTipoSequenciaPagina = {
  NAO_APLICADO: 0,
  DESENHO_TABELA: 1,
  TABELA_DESENHO: 2,
};

/** PaginaCatalogo */
const opPaginaCatalogoTipo = {
  DESENHO: 1,
  TABELA: 2,
  MISTO: 3,
  ELIMINADO: 4,
};

const opPaginaCatalogoSituacao = {
  CLASSIFICADO: 1,
  CONCLUIDO: 2,
  DESENHO_EDITADO: 3,
};

const opPaginaCatalogoOrientacao = {
  PORTRAIT: '0',
  LANDSCAPE: '1',
};

const opEditorAssociarTipoExtracao = {
  TRADICIONAL: 0,
  EASY_FAST: 1,
  EASY_DEEP: 2,
};

const opEditorTabelaTipoExtracao = {
  TRADICIONAL: 0,
  CONVENCIONAL_NODEJS: 1,
  EASY_FAST: 2,
  TABULA: 3,
  CAMELOT: 4,
};

const opSolicitacaoSituacao = {
  SOLICITADO: 0,
  CANCELADO: 1,
  TODOS: 2,
};

/** Notificacao */
const opNotificacaoTipo = {
  BANNER: 0,
  PRIVADO: 1,
};

/** Sugestao */
const opSugestaoTipo = {
  CRIACAO_FABRICANTE: '1',
  CRIACAO_MODELO: '2',
};

const opSugestaoStatus = {
  PENDENTE: 0,
  APROVADO: 1,
  REPROVADO: 2,
};

const opSugestaoOrigem = {
  CATALOGO_CRIACAO_FABRICANTE: -1000,
  CATALOGO_CRIACAO_MODELO: -1001,
};

/** Job */
const opJob = {
  EM_PROCESSAMENTO: '0',
  CONCLUIDO: '1',
  ERRO: '2',
};

export {
  opSimNao,
  opAtivoInativo,
  opLayout,
  opTipoAcao,
  opTipoEmpresa,
  conCatalogoSituacao,
  opCatalogoTipoConversao,
  opCatalogoTipoSequenciaPagina,
  opPaginaCatalogoTipo,
  opPaginaCatalogoSituacao,
  opPaginaCatalogoOrientacao,
  opEditorAssociarTipoExtracao,
  opEditorTabelaTipoExtracao,
  opSolicitacaoSituacao,
  opNotificacaoTipo,
  opSugestaoTipo,
  opSugestaoStatus,
  opSugestaoOrigem,
  opJob,
};
