import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { darken } from 'polished';

export const Container = styled.div`
  padding: 5px 0 5px 10px;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  align-items: stretch;
  transform: font-weight 1s;

  > a {
    display: flex;
    align-items: center;
    color: #888;
    font-size: 14px;

    > svg {
      margin-right: 5px;
    }

    ${(props) =>
      props.selected &&
      css`
        font-weight: bold;
        color: #2c405a;
      `}

    :hover {
      color: ${darken(0.05, '#2c405a')};
    }
  }
`;

export const Content = styled(animated.div)`
  padding: 2px 0 2px 15px;
  will-change: transform, opacity, height;
  margin-left: 6px;
  border-left: 1px dashed #aaa;
  overflow: hidden;
`;
