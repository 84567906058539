import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  useTable,
  usePagination,
  useResizeColumns,
  useFlexLayout,
} from 'react-table';

import {
  MdBuild,
  MdChevronLeft,
  MdChevronRight,
  MdDelete,
  MdFirstPage,
  MdLastPage,
  MdAddBox,
} from 'react-icons/md';

import PropTypes from 'prop-types';

import { Form, Row } from '../../Form';
import Select from '../../Form/Input/Select';

import { Table, Pagination, OperationsContainer } from './styles';

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    updateMyData(index, id, value);
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleKeyDown = (e) => {
    /** seleciona próxima linha ou coluna conforme direcionais do teclado  */
    if (e.ctrlKey) {
      let [col, row] = e.target.id.split('-');

      switch (e.keyCode) {
        case 37:
          col = Number(col) - 1;
          break;
        case 38:
          row = Number(row) - 1;
          break;
        case 39:
          col = Number(col) + 1;
          break;
        case 40:
          row = Number(row) + 1;
          break;
        default:
          break;
      }

      const el = document.getElementById(`${col}-${row}`);
      if (el) el.focus();
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      id={`${id.replace('col', '')}-${index}`}
      name={`${id.replace('col', '')}-${index}`}
      value={value}
      autoComplete="off"
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  );
};

const RowOperations = ({ row: { id }, deleteData, insertRow }) => (
  <OperationsContainer>
    <button type="button" title="Excluir linha" onClick={() => deleteData(id)}>
      <MdDelete size={18} />
    </button>
    <button
      type="button"
      title="Inserir linha acima"
      onClick={() => insertRow(id)}
    >
      <MdAddBox size={18} />
    </button>
  </OperationsContainer>
);

const SelectedHeader = ({
  column,
  options,
  data,
  updateHeader,
  updateData,
  updateHistory,
  insertHeader,
  deleteHeader,
}) => {
  const formRef = useRef();

  const handleOrganize = (id) => {
    const columnValue = formRef.current.getFieldValue(id);

    if (!columnValue) return;
    const option = options.findIndex((item) => item.value === columnValue);

    const rowsHistory = [];

    /** funções de validação */
    const isEmpty = (value) => value === '';
    const isSingle = (value) => value.length === 1;
    const isNotAllowed = (value) => /[^A-Za-z0-9./@-]+$/gi.test(value);
    const isSpecialCharSequence = (value) => /[./@-]{2}/gi.test(value);
    const isInvalidAmount = (value) =>
      value.trim() === '' || Number.isNaN(parseFloat(value));
    const clearField = (value) => isSingle(value) && isNotAllowed(value);

    const updateRow = (index, value, row, ignoreHistory) => {
      rowsHistory.push({
        rowBefore: row,
        rowAfter: { ...row, [id]: value },
        rowIndex: index,
      });
      updateData(index, id, value, ignoreHistory);
    };

    switch (option) {
      case 0: // item
      case 1: // partnumber
        data.forEach((row, index) => {
          const value = row[id].trim();

          if (
            isEmpty(value) ||
            clearField(value) ||
            isSpecialCharSequence(value) ||
            isNotAllowed(value)
          ) {
            updateRow(index, '@', row, true);
          }
        });

        break;
      case 2: // descricao
      case 4: // nota
      case 5: // espec. tecnica
        data.forEach((row, index) => {
          const value = row[id].trim();

          /** Verifica continuidade de especificação */
          const hasContinuity =
            index > 0 &&
            Object.values(row || {}).filter((v) => v.trim() !== '').length <= 2;

          /** Concatena especificações quando detectada continuidade (apenas especificação preenchida) */
          if (hasContinuity && value !== '') {
            const prevRow = data[index - 1];
            const prevValue = prevRow[id];
            const concatValue = `${prevValue} ${value}`.trim();

            updateRow(index - 1, concatValue, prevRow, true);
            updateRow(index, '', row, true);
          }

          /** Limpa campo se tamanho = 1 e não são caracteres permitidos */
          if (clearField(value)) {
            updateRow(index, '', row, true);
          }
        });

        break;
      case 3: // quantidade
        data.forEach((row, index) => {
          if (isInvalidAmount(row[id])) updateRow(index, '0', row, true);
        });

        break;
      case 6: // ordena
        data.forEach((row, index, rows) => {
          const ordena = Number(rows[0][id]) + Number(index);

          if (index > 0 && ordena !== Number(rows[index][id])) {
            updateRow(
              index,
              Number.isNaN(ordena) ? '' : String(ordena),
              row,
              true
            );
          }
        });

        break;
      default:
        break;
    }

    if (rowsHistory.length) {
      updateHistory(rowsHistory);
    }
  };

  useEffect(() => {
    /** adiciona valor padrão para o cabeçalho */
    const valorPadrao = options.find((o) => o.value === column.option) || null;
    formRef.current.setFieldValue(`${column.id}`, valorPadrao);
  }, [column, options]);

  return (
    <Form ref={formRef} onSubmit={updateHeader} autoComplete="off">
      <Row>
        <Select
          id={column.id}
          name={column.id}
          placeholder={column.Header}
          options={options}
          isClearable
          onBlur={() => formRef.current.submitForm()}
        />
      </Row>
      <OperationsContainer>
        <button
          type="button"
          title="Excluir coluna"
          onClick={() => deleteHeader(column.id)}
        >
          <MdDelete size={18} />
        </button>
        <button
          type="button"
          title="Adicionar coluna à esquerda"
          onClick={() => insertHeader(column.id)}
        >
          <MdAddBox size={18} />
        </button>
        <button
          type="button"
          title="Ajustar coluna"
          onClick={() => handleOrganize(column.id)}
        >
          <MdBuild size={18} />
        </button>
      </OperationsContainer>
    </Form>
  );
};

export default function EditableTable({
  columns,
  data,
  headers,
  updateMyHeaderData,
  updateMyData,
  deleteMyData,
  insertNewRow,
  insertMyColumn,
  deleteMyColumn,
  historyUpdate,
  skipPageReset,
}) {
  const defaultColumn = useMemo(
    () => ({
      Cell: EditableCell,
      Header: <div />,
      minWidth: 30,
      width: 200,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    gotoPage,
    pageCount,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateMyData,
      deleteData: deleteMyData,
      insertRow: insertNewRow,
    },
    usePagination,
    useResizeColumns,
    useFlexLayout,
    (hooks) => {
      hooks.allColumns.push((cols) => [
        {
          id: 'selection',
          disableResizing: true,
          width: 100,
          Header: () => <div />,
          Cell: RowOperations,
        },

        ...cols,
      ]);
    }
  );

  return (
    <>
      <Pagination>
        <button
          type="button"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <MdFirstPage size={20} />
        </button>
        <button
          type="button"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <MdChevronLeft size={20} />
        </button>
        <button
          type="button"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <MdChevronRight size={20} />
        </button>
        <button
          type="button"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <MdLastPage size={20} />
        </button>
        <span>{`${pageIndex + 1} de ${pageOptions.length}`}</span>
        <span>
          Ir para página:
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const currentPage = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(currentPage);
            }}
            style={{ width: '100px' }}
          />
        </span>
      </Pagination>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups &&
            headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.canResize && (
                      <>
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          }`}
                        />
                        <SelectedHeader
                          column={column}
                          options={headers}
                          data={data}
                          updateHeader={updateMyHeaderData}
                          updateData={updateMyData}
                          updateHistory={historyUpdate}
                          insertHeader={insertMyColumn}
                          deleteHeader={deleteMyColumn}
                        />
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

EditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.objectOf(Object).isRequired,
  column: PropTypes.objectOf(Object).isRequired,
  updateMyData: PropTypes.func.isRequired,
};

EditableCell.defaultProps = {
  value: '',
};

EditableTable.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  headers: PropTypes.arrayOf(Object).isRequired,
  updateMyHeaderData: PropTypes.func.isRequired,
  updateMyData: PropTypes.func.isRequired,
  deleteMyData: PropTypes.func.isRequired,
  insertNewRow: PropTypes.func.isRequired,
  insertMyColumn: PropTypes.func.isRequired,
  deleteMyColumn: PropTypes.func.isRequired,
  historyUpdate: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
};

RowOperations.propTypes = {
  row: PropTypes.objectOf(Object).isRequired,
  deleteData: PropTypes.func.isRequired,
  insertRow: PropTypes.func.isRequired,
};

SelectedHeader.propTypes = {
  column: PropTypes.objectOf(Object).isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  options: PropTypes.arrayOf(Object).isRequired,
  updateHeader: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  updateHistory: PropTypes.func.isRequired,
  insertHeader: PropTypes.func.isRequired,
  deleteHeader: PropTypes.func.isRequired,
};
