import React from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from 'react-responsive';
import { Container, Wrapper, Background } from './styles';

function AuthLoginLayout({ children }) {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <Container>
      <Wrapper>{children}</Wrapper>

      {!isMobile && <Background />}
    </Container>
  );
}

AuthLoginLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export { AuthLoginLayout };
