const initialParams = {
  FLT_IDS: null,
  FLT_IDL: null,
};

function useQuery(searchLocation) {
  return new URLSearchParams(searchLocation);
}

function useQueryParams(location, currentPage = 0) {
  const params = Object.fromEntries(new URLSearchParams(location.search) || []);

  Object.assign(params, { page: currentPage });

  const queryParams = Object.keys(params)
    .reduce((acc, curr) => {
      if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
      return acc;
    }, [])
    .join('&');

  return [params, queryParams];
}

export { useQuery, useQueryParams, initialParams };
