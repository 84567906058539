export function adicionaCarrinhoRequest(
  idCatalogo,
  idPagina,
  idItem,
  idMaterial,
  quantidade = 1,
  codimate = null
) {
  return {
    type: '@cart/ADD_REQUEST',
    idCatalogo,
    idPagina,
    idItem,
    idMaterial,
    quantidade,
    codimate,
  };
}

export function adicionaCarrinhoSuccess(item) {
  return {
    type: '@cart/ADD_SUCCESS',
    item,
  };
}

export function removeCarrinho(
  idCatalogo,
  idPagina,
  idItem,
  idMaterial,
  codimate = null
) {
  return {
    type: '@cart/REMOVE',
    idCatalogo,
    idPagina,
    idItem,
    idMaterial,
    codimate,
  };
}

export function limpaCarrinho() {
  return {
    type: '@cart/CLEAR',
  };
}

export function atualizaQuantidadeRequest(
  idCatalogo,
  idPagina,
  idItem,
  idMaterial,
  quantidade,
  codimate = null
) {
  return {
    type: '@cart/UPDATE_AMOUNT_REQUEST',
    idCatalogo,
    idPagina,
    idItem,
    idMaterial,
    quantidade: Number(quantidade),
    codimate,
  };
}

export function atualizaQuantidadeSuccess(
  idCatalogo,
  idPagina,
  idItem,
  idMaterial,
  quantidade,
  codimate
) {
  return {
    type: '@cart/UPDATE_AMOUNT_SUCCESS',
    idCatalogo,
    idPagina,
    idItem,
    idMaterial,
    quantidade: Number(quantidade),
    codimate,
  };
}

export function atualizaItemOSRequest(
  idCatalogo,
  idPagina,
  idItem,
  idMaterial,
  itemOS,
  codimate = null
) {
  return {
    type: '@cart/UPDATE_ITEMOS_REQUEST',
    idCatalogo,
    idPagina,
    idItem,
    idMaterial,
    itemOS,
    codimate,
  };
}

export function atualizaItemOSSuccess(
  idCatalogo,
  idPagina,
  idItem,
  idMaterial,
  itemOS,
  codimate
) {
  return {
    type: '@cart/UPDATE_ITEMOS_SUCCESS',
    idCatalogo,
    idPagina,
    idItem,
    idMaterial,
    itemOS,
    codimate,
  };
}
