import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import history from '../../../../services/history';

import TitleBar from '../../../../components/TitleBar';
import TableHeader from '../../../../components/Table/TableHeader';
import { Table, RowMaster } from '../../../../components/Table';
import ActionMenu from '../../../../components/ActionMenu';

import { AppError } from '../../../../errors/AppError';
import { opSimNao } from '../../../../lib/const';

import Pagination from '../../../../components/EditorAI/Pagination';

// import { opTokenDuracao, opTokenTipo } from '../../../../lib/inputOption';

function EmpresaCatalogoToken() {
  const location = useLocation();
  const { idEmpresa, idCatalogo } = location.state;

  const [empresa, setEmpresa] = useState(null);
  const [catalogo, setCatalogo] = useState(null);
  const [tokenAcesso, setTokenAcesso] = useState([]);

  /** controle de páginas */
  const paginationRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const carregaTokenAcesso = useCallback(async () => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { q, page } = params || [];

    const configFiltro = {
      q,
      page: Number(page),
    };

    api
      .get(`empresa/${idEmpresa}/catalogo/${idCatalogo}/token-acesso`, {
        params: configFiltro,
      })
      .then((response) => {
        setEmpresa(response.data.empresa);
        setCatalogo(response.data.catalogo);
        setTokenAcesso(
          response.data.tokenAcesso.map((token) => ({
            ...token,
            instInc: format(parseISO(token.instInc), 'dd/MM/yyyy HH:mm:ss'),
            situacao: token.dataDesa ? 'Expirado' : 'Ativo',
            // tipo: opTokenTipo[token.tipo].label,
            // duracao: opTokenDuracao[token.duracao].label,
          }))
        );
        /** ajusta dados da página */
        setTotalPage(response.data.total);
        if (page > response.data.total) {
          paginationRef.current.setPage(1);
          setCurrentPage(1);
        }
      });
  }, [location, idEmpresa, idCatalogo]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(location.search) || []
    );
    const { page = 1 } = params || {};

    if (!currentPage) {
      setCurrentPage(Number(page));
      return;
    }

    Object.assign(params, { page: currentPage });

    const queryParams = Object.keys(params)
      .reduce((acc, curr) => {
        if (params[curr]) acc.push(`${curr}=${encodeURI(params[curr])}`);
        return acc;
      }, [])
      .join('&');

    if (`?${queryParams}` !== location.search) {
      history.replace(`${location.pathname}?${queryParams}`, location.state);
      return;
    }

    carregaTokenAcesso();
  }, [location, currentPage, carregaTokenAcesso]);

  const handleCriar = useCallback(() => {
    history.push('/empresa/catalogo/token-acesso/criar', {
      background: location,
      idEmpresa,
      idCatalogo,
    });
  }, [location, idEmpresa, idCatalogo]);

  const handleEditar = useCallback(
    (idToken) => {
      history.push('/empresa/catalogo/token-acesso/editar', {
        background: location,
        idEmpresa,
        idCatalogo,
        id: idToken,
      });
    },
    [location, idEmpresa, idCatalogo]
  );

  const handleRemover = useCallback(
    async (idToken) => {
      if (window.confirm('Deseja realmente desabilitar o token de acesso?')) {
        try {
          await api.delete(
            `empresa/${idEmpresa}/catalogo/${idCatalogo}/token-acesso/${idToken}`
          );

          carregaTokenAcesso();

          toast.success('Desabilitado com sucesso!');
        } catch (err) {
          AppError(err);
        }
      }
    },
    [idEmpresa, idCatalogo, carregaTokenAcesso]
  );

  const handleQRCode = useCallback(
    async (idToken) => {
      const basePath = process.env.REACT_APP_HOMEPAGE;

      const url = basePath.endsWith('/')
        ? `${basePath}catalogo-token?token=`
        : `${basePath}/catalogo-token?token=`;

      try {
        const response = await api.post(
          `empresa/${idEmpresa}/catalogo/${idCatalogo}/token-acesso/${idToken}/qrcode`,
          {
            url: String(url),
          }
        );

        const { data } = response;

        const link = document.createElement('a');

        link.href = data;
        link.target = '_blank';
        link.rel = 'noreferrer';
        link.download = `qrcode-catalogo-${idCatalogo}-token.png`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success('QR Code gerado com sucesso!');
      } catch (err) {
        AppError(err);
        toast.error(err);
      }
    },
    [idEmpresa, idCatalogo]
  );

  // const handleCopiarToken = useCallback((token) => {
  //   const basePath = process.env.REACT_APP_HOMEPAGE;

  //   const url = basePath.endsWith('/')
  //     ? `${basePath}catalogo-token?token=${token}`
  //     : `${basePath}/catalogo-token?token=${token}`;

  //   /** copia para o clipboard */
  //   navigator.clipboard.writeText(url);

  //   toast.success(`URL gerada e copiada para a memória!`);
  // }, []);

  const handleVisualizar = useCallback(
    (e, idToken) => {
      e.preventDefault();

      /** verifica se a linha foi clicada */
      if (e.target.nodeName.toUpperCase() === 'TD') {
        handleEditar(idToken);
      }
    },
    [handleEditar]
  );

  return (
    <>
      <TitleBar
        back
        title={
          empresa && catalogo
            ? `${empresa.descricao} / ${catalogo.nome} / Token de acesso`
            : ''
        }
        isSearchable
        onNew={handleCriar}
      />

      <Table>
        <TableHeader
          withPadding
          titles={[
            { fieldname: 'idToken', title: 'Token' },
            { fieldname: 'nome', title: 'Nome' },
            { fieldname: 'instInc', title: 'Iniciado em' },
            { fieldname: 'duracao', title: 'Valido por (dias)' },
            { fieldname: 'tipo', title: 'Tipo' },
            { fieldname: 'situacao', title: 'Situação' },
            { fieldname: null, title: null },
          ]}
          cbSort={() => {}}
        />
        <tbody>
          {tokenAcesso.map((token, index) => (
            <RowMaster
              key={index}
              onClick={(e) => handleVisualizar(e, token.idToken)}
            >
              <td>{token.idToken}</td>
              <td>{token.nome}</td>
              <td>{token.instInc}</td>
              <td>{token.duracao}</td>
              <td>{token.tipo}</td>
              <td>{token.situacao}</td>
              <td>
                <ActionMenu
                  data={token}
                  // onLineCopy={
                  //   token.dataDesa ? null : () => handleCopiarToken(token.token)
                  // }
                  // onCopy={
                  //   token.dataDesa ? null : () => handleCopiarToken(token.token)
                  // }
                  onRemove={() => handleRemover(token.idToken)}
                  onLineGenerateQRCode={
                    process.env.REACT_APP_EXPERIMENTAL_CATALOGO_TOKEN_QRCODE ===
                    opSimNao.SIM
                      ? () => handleQRCode(token.idToken)
                      : null
                  }
                />
              </td>
            </RowMaster>
          ))}
        </tbody>
      </Table>

      <Pagination
        ref={paginationRef}
        pageCount={totalPage}
        initialData={currentPage}
        onPageChange={(page) => {
          setCurrentPage(Number(page));
        }}
      />
    </>
  );
}

export { EmpresaCatalogoToken };
